import { Button } from "@mui/joy";
import { pickNotDeprecated, useOne, useResource } from "@refinedev/core";
import type { RefreshButtonProps } from "@refinedev/mui";
import React from "react";
import { RefreshCw } from "react-feather";

/**
 * `<RefreshButton>` uses uses Material UI {@link https://mui.com/material-ui/api/loading-button/#main-content `<LoadingButton>`} component
 * to update the data shown on the page via the {@link https://refine.dev/docs/core/hooks/data/useOne `useOne`} method provided by your dataProvider.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/mui/components/buttons/refresh-button} for more details.
 */
export const RefreshButton: React.FC<RefreshButtonProps> = ({
  resource: resourceNameFromProps,
  resourceNameOrRouteName,
  recordItemId,
  hideText = false,
  meta,
  metaData,
  dataProviderName,
  svgIconProps,
  children,
  onClick,
  ...rest
}) => {
  const { resource, id } = useResource(resourceNameFromProps ?? resourceNameOrRouteName);

  const { refetch, isFetching } = useOne({
    resource: resource?.name,
    id: recordItemId ?? id ?? "",
    queryOptions: {
      enabled: false,
    },
    meta: pickNotDeprecated(meta, metaData),
    metaData: pickNotDeprecated(meta, metaData),
    liveMode: "off",
    dataProviderName,
  });

  const { sx, ...restProps } = rest;

  return (
    <Button
      startDecorator={<RefreshCw size="16" {...svgIconProps} />}
      loading={isFetching}
      variant="outlined"
      color="neutral"
      loadingPosition={hideText ? "center" : "start"}
      onClick={(e) => (onClick ? onClick(e) : refetch())}
      sx={{ minWidth: 0, ...sx }}
      {...restProps}
    >
      {(!hideText && children) ?? "Refresh"}
    </Button>
  );
};

import { ThemeMode } from "@app/types/types";
import { ListItem, ListItemButton } from "@mui/joy";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";
import { useColorScheme } from "@mui/joy/styles";
import { Moon, Sun } from "react-feather";

export const ColorSchemeToggle = ({ sx, ...props }: IconButtonProps) => {
  const { mode, setMode } = useColorScheme();
  const Icon = mode === ThemeMode.LIGHT ? Moon : Sun;
  const onClick = () => {
    setMode(mode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT);
  };
  return (
    <IconButton id="toggle-mode" size="sm" variant="outlined" color="neutral" onClick={onClick} sx={sx} {...props}>
      <Icon />
    </IconButton>
  );
};

export const ColorSchemeToggleFirstMenuListItem = () => {
  const { mode, setMode } = useColorScheme();
  const Icon = mode === ThemeMode.LIGHT ? Moon : Sun;
  const onClick = () => {
    setMode(mode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT);
  };
  return (
    <ListItem onClick={onClick}>
      <ListItemButton>
        <Icon />
      </ListItemButton>
    </ListItem>
  );
};

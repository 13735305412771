import { CssBaseline, GlobalStyles } from "@mui/joy";
import { CssVarsProvider as JoyCssVarsProvider, extendTheme } from "@mui/joy/styles";
import { Experimental_CssVarsProvider as MaterialCssVarsProvider, THEME_ID, experimental_extendTheme as materialExtendTheme } from "@mui/material/styles";

const PRIMARY_FONT = "Inter";
const FONT = `'${PRIMARY_FONT}', var(--joy-fontFamily-fallback)`;

const GRAY_LINE_COLOR = "#003e7e";
const GRAY_LINE_FONT_COLOR = "#3c3c3b";

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: { 500: GRAY_LINE_COLOR },
        background: { body: "#f7f7f8" },
      },
    },
  },
  fontFamily: {
    body: FONT,
    // display: font,
  },
});

const materialTheme = materialExtendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: { main: GRAY_LINE_COLOR },
      },
    },
  },
  typography: {
    fontFamily: FONT,
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: theme.zIndex.popup - 1,
        },
      },
    },
  },
});

export const useTheme = () => {
  return theme;
};

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
    <JoyCssVarsProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{ html: { fontSize: 16 } }} />
      {children}
    </JoyCssVarsProvider>
  </MaterialCssVarsProvider>
);

import { HomePage } from "@app/pages/home";
import { Login } from "@app/pages/login";
import { ResourcesRoutes } from "@app/resources";
import { RESOURCES_BASE_PATH } from "@app/resources/common/constants";
import { CustomThemedLayoutV2 } from "@app/ui/refine-components/layout";
import { Header } from "@app/ui/refine-components/layout/header";
import { Sidebar } from "@app/ui/refine-components/layout/sidebar";
import { Authenticated } from "@refinedev/core";
import { ErrorComponent } from "@refinedev/mui";
import { CatchAllNavigate, NavigateToResource } from "@refinedev/react-router-v6";
import { Outlet, Route, Routes } from "react-router-dom";

export const RoutesProvider = () => (
  <Routes>
    <Route
      element={
        <Authenticated fallback={<CatchAllNavigate to="/authentication/login" />}>
          <CustomThemedLayoutV2 Header={Header} Sider={Sidebar}>
            <Outlet />
          </CustomThemedLayoutV2>
        </Authenticated>
      }
    >
      <Route path={`/`} Component={HomePage} />
      <Route path={`${RESOURCES_BASE_PATH}/*`} element={<ResourcesRoutes />} />
    </Route>
    <Route
      element={
        <Authenticated fallback={<Outlet />}>
          <NavigateToResource resource="resources" />
        </Authenticated>
      }
    >
      <Route path="/authentication/login" element={<Login />} />
    </Route>
    <Route
      element={
        <Authenticated>
          <CustomThemedLayoutV2 Header={Header}>
            <Outlet />
          </CustomThemedLayoutV2>
        </Authenticated>
      }
    >
      <Route path="*" element={<ErrorComponent />} />
    </Route>
  </Routes>
);

export type TourcmsBooking = {
  id: string;
  lead_customer_tel_home_e164?: string;
  lead_customer_tel_mobile_e164?: string;
  booking_id: string;
  channel_id: string;
  account_id: string;
  channel_name: string;
  made_date_time: string;
  // Can be "webapimp"
  made_username: string;
  // Can be "W"
  made_type: string;
  made_name: string;
  start_date: string;
  end_date: string;
  booking_name: string;
  booking_name_custom: string;
  status: Status;
  status_text: string;
  voucher_url: string;
  barcode_data: string;
  cancel_reason: CancelReason;
  cancel_text: string;
  final_check: string;
  lead_customer_id: string;
  lead_customer_name: string;
  lead_customer_firstname: string;
  lead_customer_surname: string;
  lead_customer_country: string;
  lead_customer_city: string;
  lead_customer_postcode: string;
  lead_customer_address: string;
  lead_customer_email: string;
  lead_customer_tel_home: string;
  lead_customer_tel_mobile: string;
  lead_customer_contact_note: string;
  lead_customer_agent_ref: string;
  lead_customer_travelling: string;
  customer_count: number;
  sale_currency: Currency;
  sales_revenue: string;
  sales_revenue_display: string;
  deposit: string;
  deposit_display: string;
  agent_type: AgentType;
  agent_id: string;
  marketplace_agent_id: string;
  agent_name: string;
  agent_code: string;
  agent_credentials: string;
  agent_ref: string;
  agent_ref_components: string;
  tracking_miscid: string;
  commission: string;
  commission_tax: string;
  commission_currency: Currency;
  commission_display: string;
  commission_tax_display: string;
  booking_has_net_price: string;
  payment_status: PaymentStatus;
  payment_status_text: string;
  balance_owed_by: BalanceOwedBy;
  balance: string;
  balance_display: string;
  balance_due: string;
  customer_special_request: string;
  customers_agecat_breakdown: string;
  payments?: Payments;
  important_note: string;
  workflow_note: string;
  components: Components;
  cancel_date_time?: string;
};

export enum Status {
  QUOTE = "0",
  PROVISIONAL = "1",
  CONFIRMED = "2",
  CONFIRMED_AND_ARCHIVED = "3",
}

export enum AgentType {
  Trusted = "TRUSTED",
}

export enum BalanceOwedBy {
  AGENT = "A",
  CUSTOMER = "C",
}
/*
  0 - Not cancelled
  1 - Cancelled by customer request, see notes
  2 - Cancelled by customer request, booked another tour
  3 - Cancelled by staff, see notes
  4 - Cancelled by staff, boook another tour
  5 - Cancelled by staff, payment not received
  6 - Cancelled by staff, tour not reached min. numbers
  7 - Cancelled by staff, booking made in error
  8 - Cancelled by staff, capacity exceeded
  9 - Cancelled by staff, operational reasons
  10 - Cancelled by staff, booking reopened for editing
  11 - Cancelled by staff, bad weather
  20 - Cancelled by system, expired quotation
  21 - Cancelled by system, expired provisional booking
  */
export enum CancelReason {
  NotCancelled = "0",
  CancelledByCustomerRequestSeeNotes = "1",
  CancelledByCustomerRequestBookedAnotherTour = "2",
  CancelledByStaffSeeNotes = "3",
  CancelledByStaffBoookAnotherTour = "4",
  CancelledByStaffPaymentNotReceived = "5",
  CancelledByStaffTourNotReachedMinNumbers = "6",
  CancelledByStaffBookingMadeInError = "7",
  CancelledByStaffCapacityExceeded = "8",
  CancelledByStaffOperationalReasons = "9",
  CancelledByStaffBookingReopenedForEditing = "10",
  CancelledByStaffBadWeather = "11",
  CancelledBySystemExpiredQuotation = "20",
  CancelledBySystemExpiredProvisionalBooking = "21",
}

export enum Currency {
  EUR = "EUR",
}

export type Components = {
  component: Component[];
};

export type Component = {
  component_id: string;
  product_id: string;
  linked_component_id: string;
  date_id: string;
  date_type: string;
  product_code: string;
  date_code: string;
  start_date: string;
  end_date: string;
  local_payment: string;
  customer_payment: string;
  upsell_username: string;
  component_added_datetime: string;
  start_time: string;
  end_time: string;
  start_time_utcseconds: string;
  end_time_utcseconds: string;
  component_name: string;
  product_note: string;
  component_note: string;
  rate_breakdown: string;
  rate_description: string;
  rate_code: string;
  supplier_id: string;
  supplier_ref: string;
  supplier_name: string;
  supplier_tour_code: string;
  operational_note: string;
  supplier_note: string;
  pickup_id: string;
  pickup_name: string;
  pickup_time: string;
  pickup_note: string;
  pickup_route_code: string;
  pickup_description: string;
  sale_quantity: string;
  sale_quantity_rule: string;
  sale_tax_percentage: string;
  sale_tax_inclusive: string;
  sale_currency: string;
  sale_price: string;
  tax_total: string;
  sale_price_inc_tax_total: string;
  sale_exchange_rate: string;
  currency_base: string;
  sale_price_base: string;
  tax_total_base: string;
  sale_price_inc_tax_total_base: string;
  cost_quantity: string;
  cost_quantity_rule: string;
  cost_tax_percentage: string;
  cost_tax_inclusive: string;
  cost_currency: string;
  cost_price: string;
  cost_tax_total: string;
  cost_price_inc_tax_total: string;
  cost_exchange_rate: string;
  cost_price_base: string;
  cost_tax_total_base: string;
  cost_price_inc_tax_total_base: string;
  voucher_redemption_status: string;
  voucher_redemption_username: string;
  bus_checkin_status: string;
};

/*
0 - No payment
1 - Deposit paid
2 - Deposit paid #2
3 - Full balance paid
4 - Refunded
*/

export enum PaymentStatus {
  NoPayment = "0",
  DepositPaid = "1",
  DepositPaid2 = "2",
  FullBalancePaid = "3",
  Refunded = "4",
}

export type Payments = {
  payment: Payment[];
};

export type Payment = {
  payment_date_time: string;
  payment_value: string;
  payment_currency: Currency;
  payment_value_display: string;
  payment_type: string;
  payment_reference: string;
  payment_note: string;
  payment_by: PaymentBy;
  payment_by_id: string;
  payment_transaction_reference: string;
  gateway_mode: string;
};

export enum PaymentBy {
  AGENT = "A",
  CUSTOMER = "C",
}

import { Resource } from "@app/types/types";
import { Route, Routes } from "react-router-dom";
import { RESOURCES_BASE_PATH } from "../common/constants";
import { resourceBase } from "../common/resource";
import { TourcmsBookingList } from "./list";
import { TourcmsBookingShow } from "./show";

const id = "tourcms-bookings";
const path = `${RESOURCES_BASE_PATH}/${id}`;
export const tourcmsBookingResource: Resource = {
  ...resourceBase,
  id,
  path,
  relativePath: `/${id}`,
  pages: {
    list: { relativePath: "/", path: `${path}/` },
    show: { relativePath: "/:id", path: `${path}/:id` },
  },
  language: {
    singular: "Booking",
    plural: "Bookings",
  },
  api: {
    path: "/tourcms/bookings",
  },
};
export const TourcmsBookingRoutes = () => (
  <Routes>
    <Route index path={tourcmsBookingResource.pages.list.relativePath} Component={TourcmsBookingList} />
    <Route path={tourcmsBookingResource.pages.show.relativePath} Component={TourcmsBookingShow} />
  </Routes>
);

import { useAuth } from "@app/providers/auth";
import { AspectRatio, Box, Card, Chip, Grid, Typography } from "@mui/joy";
import { useNavigation } from "@refinedev/core";
import { useCallback } from "react";
import { TourcmsDeparture, tourcmsDepartureResource } from ".";
import { computeDeparture } from "./computed";

export const TourcmsDepartureListItem: React.FC<{ departure: TourcmsDeparture; selectDepartureId: (id: string) => void }> = ({
  departure,
  selectDepartureId,
}) => {
  const { isGlobalAdministrator } = useAuth();
  const { tour, image, bookings, booking_sample_component, departure_name } = computeDeparture(departure);
  const { show } = useNavigation();

  const onClick = useCallback(() => {
    if (isGlobalAdministrator) {
      selectDepartureId(departure.id);
    } else {
      show(tourcmsDepartureResource.toRefineResource(), departure.id);
    }
  }, [departure.id, selectDepartureId, show, isGlobalAdministrator]);
  return (
    <>
      <Card
        onClick={onClick}
        variant="soft"
        color="primary"
        sx={{
          transition: "250ms all",
          padding: 0,
          boxShadow: "none",
          borderRadius: "sm",
          "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
        }}
      >
        <Box p={2}>
          <Grid
            container
            direction={{
              xs: "row",
              sm: "row",
            }}
            spacing={{
              xs: 1,
              sm: 4,
            }}
            alignItems="center"
            wrap="nowrap"
          >
            <Grid
              flexShrink={0}
              sx={{
                height: "100%",
                width: {
                  xs: 50,
                  sm: 80,
                },
                marginBottom: 0,
              }}
            >
              <AspectRatio
                ratio={4 / 3}
                sx={(theme) => ({
                  borderRadius: "xs",
                  width: "100%",
                  [theme.breakpoints.down("sm")]: {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                })}
              >
                <img alt="" src={image} style={{ display: "block" }} />
              </AspectRatio>
            </Grid>
            <Grid
              sx={{
                padding: {
                  xs: 0,
                  sm: 0,
                },
              }}
              spacing={0}
              width="100%"
              flex="1 1 auto"
            >
              <Box width="100%">
                <Typography fontSize="sm" fontWeight="md" noWrap overflow="hidden" textOverflow="ellipsis" width="100%">
                  {tour.tour_name}
                </Typography>
                <Typography fontSize="xs" fontWeight="sm">
                  {departure_name}
                </Typography>
              </Box>
            </Grid>
            <Grid flexShrink={0}>
              <Chip variant="solid">{departure.customer_count}</Chip>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

import { LayoutContextProvider } from "@app/providers/layout";
import { Box } from "@mui/joy";
import { RefineThemedLayoutV2Props } from "@refinedev/mui";
import React from "react";
import { Header as DefaultHeader } from "./header";
import { Sidebar as DefaultSider } from "./sidebar";

export const CustomThemedLayoutV2: React.FC<RefineThemedLayoutV2Props> = ({ Sider, Header, Title, Footer, OffLayoutArea, children, initialSiderCollapsed }) => {
  const SiderToRender = Sider ?? DefaultSider;
  const HeaderToRender = Header ?? DefaultHeader;

  return (
    <LayoutContextProvider>
      <Box display="flex" flexDirection="row" width="100vw">
        <SiderToRender />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            minHeight: "100vh",
            overflowX: "hidden",
          }}
        >
          <HeaderToRender />
          <Box
            component="main"
            sx={{
              mt: `var(--Header-height)`,
              p: { xs: 2, md: 5, lg: 10 },
              pt: { xs: 1, md: 2, lg: 3 },
              flexGrow: 1,
              bgcolor: (theme) => theme.palette.background.body,
              height: "100%",
            }}
          >
            {children}
          </Box>
          {Footer && <Footer />}
        </Box>
        {OffLayoutArea && <OffLayoutArea />}
      </Box>
    </LayoutContextProvider>
  );
};

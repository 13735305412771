import { Resource } from "@app/types/types";
import { Route, Routes } from "react-router-dom";
import { RESOURCES_BASE_PATH } from "../common/constants";
import { resourceBase } from "../common/resource";
import { TourcmsTourList } from "./list";
import { TourcmsTourShow } from "./show";

const id = "tourcms-tours";
const path = `${RESOURCES_BASE_PATH}/${id}`;
export const tourcmsTourResource: Resource = {
  ...resourceBase,
  id,
  path,
  relativePath: `/${id}`,
  pages: {
    list: { relativePath: "/", path: `${path}/` },
    show: { relativePath: "/:id", path: `${path}/:id` },
  },
  language: {
    singular: "Tour",
    plural: "Tours",
  },
  api: {
    path: "/tourcms/tours",
  },
};
export const TourcmsTourRoutes = () => (
  <Routes>
    <Route index path={tourcmsTourResource.pages.list.relativePath} Component={TourcmsTourList} />
    <Route path={tourcmsTourResource.pages.show.relativePath} Component={TourcmsTourShow} />
  </Routes>
);

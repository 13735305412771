import { CalendarIcon as HeroCalendarIcon } from "@heroicons/react/24/outline";
import { Box } from "@mui/joy";
import { FC } from "react";
export const CalendarIcon: FC<{ text?: string }> = ({ text }) => (
  <>
    <Box position="relative">
      <Box width="100%">
        <HeroCalendarIcon />
      </Box>
      {/* <Box left={0} top="30%" position="absolute" width="100%" height="100%">
        <Typography textAlign="center" fontSize="lg" fontWeight="sm" width="100%" height="100%">
          {text}
        </Typography>
      </Box> */}
    </Box>
  </>
);

import { useAuth0 } from "@auth0/auth0-react";
import { AuthBindings } from "@refinedev/core";
import axios from "axios";
import React, { createContext, useState } from "react";

export interface User {
  id: string;
  name: string;
  email?: string;
  telephone?: string;
  picture?: string;
  roles: string[];
}

export interface AuthContextValues {
  user: User | null;
  setUser: (user: User | null) => void;
}

const authContextDefault: AuthContextValues = { user: null, setUser: () => {} };
export const AuthContext = createContext(authContextDefault);

export const AuthContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState(authContextDefault.user);
  console.log("USER CONTEXT", user);
  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
};

const SUCCESS_RESPONSE = { success: true };
const AUTHENTICATED_RESPONSE = { authenticated: true };
const UNAUTHENTICATED_RESPONSE = {
  authenticated: false,

  redirectTo: "/authentication/login",
  logout: true,
};

const TOKEN_NOT_FOUND_ERROR = {
  message: "Authentication check failed",
  name: "Token not found",
};

const LOGIN_REDIRECT_URI = window.location.origin;
const LOGOUT_REDIRECT_URI = window.location.origin;

const computeUserIdentity = (user: User) => ({
  ...user,
  avatar: user.picture,
});

const BACKEND_USERINFO_URL = `${import.meta.env.VITE_LOCAL_BACKEND_URL}/user/info`;

export const useAuth = () => {
  const { loginWithRedirect, logout, getIdTokenClaims, user: auth0User, isLoading } = useAuth0();
  const { user, setUser } = React.useContext(AuthContext);
  const isGlobalAdministrator = user?.roles?.includes("GlobalAdministrator");
  // console.log(user);
  const authProvider: AuthBindings = {
    login: async () => {
      loginWithRedirect({ authorizationParams: { redirect_uri: LOGIN_REDIRECT_URI } });
      return SUCCESS_RESPONSE;
    },
    logout: async () => {
      logout({ logoutParams: { returnTo: LOGOUT_REDIRECT_URI } });
      return SUCCESS_RESPONSE;
    },
    onError: async (error) => {
      console.error("authProvider.onError", error);
      return { error };
    },
    check: async () => {
      // console.log("authProvider.check");
      try {
        const token = await getIdTokenClaims();
        // console.log("authProvider.check - token ", token);

        if (user) return AUTHENTICATED_RESPONSE;

        if (token) {
          axios.defaults.headers.common = { Authorization: `Bearer ${token.__raw}` };
          const response = await axios.get(BACKEND_USERINFO_URL);
          setUser(response.data);
          return AUTHENTICATED_RESPONSE;
        }

        loginWithRedirect({ authorizationParams: { redirect_uri: LOGIN_REDIRECT_URI } });
        return { ...UNAUTHENTICATED_RESPONSE, error: TOKEN_NOT_FOUND_ERROR };
      } catch (error: any) {
        loginWithRedirect({ authorizationParams: { redirect_uri: LOGIN_REDIRECT_URI } });
        return { ...UNAUTHENTICATED_RESPONSE, error: new Error(error) };
      }
    },
    getPermissions: async () => undefined,
    getIdentity: async () => (user ? computeUserIdentity(user) : null),
  };

  return { authProvider, isLoading, user, isGlobalAdministrator };
};

import AspectRatio, { AspectRatioProps } from "@mui/joy/AspectRatio";

export function Logo({ sx, ...props }: AspectRatioProps) {
  return (
    <AspectRatio
      // ratio="1"
      variant="plain"
      objectFit="contain"
      {...props}
      sx={[
        {
          width: 50,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <div>
        <img src="/logo.png" />
      </div>
    </AspectRatio>
  );
}

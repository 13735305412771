import { FormControl, FormHelperText, FormHelperTextProps, Input, InputProps } from "@mui/joy";
import { FormContext } from "@refinedev/mantine";
import { ReactNode } from "react";
import { FormLabel, FormLabelProps } from "../form-label";

type TextFieldProps = InputProps & {
  name: string;
  label: string;
  helperText?: string | ReactNode;
  slotProps?: { label?: FormLabelProps; helper?: FormHelperTextProps };
};

export const TextField: React.FC<TextFieldProps> = ({ label, helperText, name, ...inputProps }: TextFieldProps) => {
  const { getInputProps, errors } = FormContext.useFormContext();
  const formInputProps = getInputProps(name);
  const error = errors[name];
  const value = formInputProps.value ? formInputProps.value : "";
  return (
    <FormControl>
      <FormLabel component="h2">{label}</FormLabel>
      <Input {...formInputProps} {...inputProps} value={value} />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

import { useAuth } from "@app/providers/auth";
import { useLayoutContext } from "@app/providers/layout";
import { useMainMenu } from "@app/providers/menu";
import { useTheme } from "@mui/joy";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListSubheader from "@mui/joy/ListSubheader";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { Backdrop } from "@mui/material";
import { useLogout } from "@refinedev/core";
import * as React from "react";
import { LogOut } from "react-feather";
import { Link } from "react-router-dom";

interface IUser {
  id: number;
  name: string;
  avatar: string;
  email: string;
}

export const SecondSidebar = () => {
  const logout = useLogout();
  const { selected, preview } = useMainMenu();
  const { mobileSiderOpen, setMobileSiderOpen, menuPreviewKey, setMenuPreviewKey, closeSidebar } = useLayoutContext();
  const {  user } = useAuth()
  const title = preview?.label || selected?.label || "";
  const items = preview?.items || selected?.items || [];

  console.log("USER", user);

  const hasItems = items.length > 0;

  const currentPageHasSecondSidebar = selected?.items && selected?.items.length > 0;
  const isPreview = !!preview;

  const transformOpenMobile = `translateX(calc(100% * (${mobileSiderOpen ? 1 : 0} - 1) + ${mobileSiderOpen ? 1 : 0} * var(--FirstSidebar-width, 0px)))`;
  const transformOpenPreviewNoCurrentPageSidebar = `translateX(calc(100% * (${isPreview ? 1 : 0} - 1) + var(--FirstSidebar-width, 0px)))`;

  const handleLogout = React.useCallback(() => {
    logout.mutate();
  }, [logout]);

  const theme = useTheme();

  return (
    <React.Fragment>
      <Backdrop open={!!mobileSiderOpen || !!isPreview} onClick={closeSidebar} sx={{ zIndex: theme.zIndex.popup - 2 }} />
      <Sheet
        className="SecondSidebar"
        sx={{
          position: {
            xs: "fixed",
            lg: currentPageHasSecondSidebar ? "sticky" : "fixed",
          },
          transform: {
            xs: transformOpenMobile,
            md: transformOpenPreviewNoCurrentPageSidebar,
            lg: currentPageHasSecondSidebar ? "none" : transformOpenPreviewNoCurrentPageSidebar,
          },
          borderRight: "1px solid",
          borderColor: "divider",
          transition: "transform 0.4s",
          zIndex: theme.zIndex.popup - 1,
          height: "100dvh",
          top: 0,
          p: 2,
          py: 3,
          flexShrink: 0,
          display: hasItems ? "flex" : "none",
          flexDirection: "column",
          gap: 2,
          width: "300px",
        }}
      >
        <List
          sx={{
            "--ListItem-radius": "8px",
            "--ListItem-minHeight": "32px",
            "--List-gap": "4px",
            "& svg": {
              width: 20,
              height: 20,
            },
          }}
        >
          <ListSubheader role="presentation" sx={{ color: "text.primary" }}>
            {title}
          </ListSubheader>
          {items.map((item) => (
            <ListItem>
              <ListItemButton onClick={closeSidebar} component={Link} to={item.path} {...(item.selected && { selected: true, variant: "soft" })}>
                <ListItemDecorator>{item.icon?.()}</ListItemDecorator>
                <ListItemContent>{item.label}</ListItemContent>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box sx={{ pl: 1, mt: "auto", display: "flex", alignItems: "center" }}>
          <div>
            <Typography fontWeight="lg" level="body2">
              {user?.name}
            </Typography>
            <Typography level="body2">{user?.email}</Typography>
          </div>
          <IconButton variant="plain" sx={{ ml: "auto" }} onClick={handleLogout}>
            <LogOut />
          </IconButton>
        </Box>
      </Sheet>
    </React.Fragment>
  );
};

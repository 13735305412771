import { List } from "@app/ui/refine-components/crud/list";
import { Box, Input, Sheet, Typography } from "@mui/joy";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { IResourceComponentsProps, useGetToPath } from "@refinedev/core";
import { useDataGrid } from "@refinedev/mui";
import { useDebounce } from "@uidotdev/usehooks";
import dayjs from "dayjs";
import { decodeHTML } from "entities";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { TourcmsBooking, tourcmsBookingResource } from ".";
import { applyGridColumnDefaults } from "../common/list";
import { EmailChip, TelephonesChips } from "./components";
import { StatusChip } from "./status";

export const TourcmsBookingList: React.FC<IResourceComponentsProps> = () => {
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  const onSearchChange = useCallback((event) => setSearch(event.target.value), [setSearch]);

  const { dataGridProps } = useDataGrid<TourcmsBooking>({ meta: { search: debouncedSearchTerm } });
  const getToPath = useGetToPath();
  const columns = useMemo<GridColumns<TourcmsBooking>>(
    () =>
      applyGridColumnDefaults([
        {
          field: "booking_id",
          headerName: "ID",
          type: "number",
          minWidth: 200,
          flex: 2,
          align: "left",
          headerAlign: "left",
          renderCell: ({ row }) => (
            <Box>
              <Typography
                component={Link}
                to={getToPath({ action: "show", resource: tourcmsBookingResource.toRefineResource(), meta: { id: row.id } })}
                level="body1"
                fontSize="sm"
                fontWeight="md"
              >
                TCM {row.booking_id}
              </Typography>
              <Typography level="body3">{row.agent_ref}</Typography>
            </Box>
          ),
        },
        {
          field: "agent_name",
          headerName: "Agent",
          flex: 1,
          minWidth: 150,
          renderCell: ({ row }) => (
            <Typography level="body1" variant="outlined" fontSize={"sm"}>
              {row.agent_name}
            </Typography>
          ),
        },
        {
          field: "start_date",
          headerName: "Date",
          type: "date",
          minWidth: 200,
          flex: 2,
          sortable: true,
          sortingOrder: ["desc", "asc"],
          valueFormatter: ({ value }) => dayjs(value).format("dddd, D MMMM YY"),
        },
        {
          field: "booking_name",
          headerName: "Service",
          flex: 4,
          valueFormatter: ({ value }) => decodeHTML(value),
        },
        {
          field: "customer_count",
          headerName: "PAX",
          minWidth: 75,
          maxWidth: 100,
          flex: 1,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "contact",
          headerName: "Contact",
          flex: 4,
          renderCell: ({ row }: { row: TourcmsBooking }) => (
            <Box>
              <Typography pb={0.5} level="body1" fontWeight="md" fontSize="sm">
                {row.lead_customer_name}
              </Typography>
              <TelephonesChips booking={row} />
              <EmailChip booking={row} />
            </Box>
          ),
        },
        {
          field: "status_text",
          headerName: "Status",
          minWidth: 150,
          maxWidth: 300,
          flex: 1,
          align: "center",
          headerAlign: "center",
          renderCell: ({ row }) => <StatusChip booking={row} />,
        },
      ]),
    []
  );

  return (
    <>
      <List resource={tourcmsBookingResource.id} title="Bookings">
        <Box mb={2}>
          <Input size="lg" placeholder="Smart Search" variant="solid" value={search} onChange={onSearchChange} />
        </Box>
        <Sheet
          variant="outlined"
          sx={{
            borderRadius: "md",
            flex: 1,
            minHeight: 0,
            overflow: "hidden",
          }}
        >
          <DataGrid
            {...dataGridProps}
            columns={columns}
            autoHeight
            rowHeight={60}
            sx={{
              border: 0,
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader": {
                backgroundColor: "var(--joy-palette-neutral-50)",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
          />
        </Sheet>
      </List>
    </>
  );
};

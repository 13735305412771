import { useLayoutContext } from "@app/providers/layout";
import { IconButton } from "@mui/joy";
import React from "react";
import { Menu } from "react-feather";

const HamburgerIcon = (props: React.PropsWithChildren) => (
  <IconButton color="inherit" aria-label="open drawer" edge="start" {...props}>
    <Menu />
  </IconButton>
);
export const Hamburger: React.FC = () => {
  const { mobileSiderOpen, setMobileSiderOpen } = useLayoutContext();
  // console.log(`hamburger - ${mobileSiderOpen}`);

  return (
    <>
      <HamburgerIcon
        onClick={() => {
          // console.log("Setting mobile sider open to true");
          setMobileSiderOpen?.(true);
        }}
        sx={{
          mr: 2,
          ...(mobileSiderOpen && { display: "none" }),
        }}
      />
    </>
  );
};

import { FirstSidebar } from "./first";
import { SecondSidebar } from "./second";

export const Sidebar = () => {
  
  return (
    <>
      <FirstSidebar />
      <SecondSidebar />
    </>
  );
};

import { Checkbox, CheckboxGroup, CheckboxGroupItem } from "@app/ui/form/checkbox";
import { TextField } from "@app/ui/form/text-field";
import { ModalFormProps } from "@app/ui/refine-extensions/modal/drawer-form";
import { Drawer } from "@mantine/core";
import { Button, Stack, useTheme } from "@mui/joy";
import { Collapse } from "@mui/material";
import { FormContext } from "@refinedev/mantine";
import { useEffect } from "react";
import { Resource } from ".";

export const EditResourceDrawer: React.FC<ModalFormProps<Resource, Resource>> = ({ form }) => {
  // console.log(modalForm);
  // const { selectProps } = useSelect({
  //   resource: modalForm.refineCore.id,
  //   defaultValue: queryResult?.data?.data.category.id,
  // });
  // console.log(queryResult);
  const modal = form.modal;
  const { errors, values, saveButtonProps } = form;

  const theme = useTheme();
  useEffect(() => {
    if (!modal.visible) {
      form.reset();
    }
    const fetchedValues = form.refineCore.queryResult?.data?.data;
    // console.log(fetchedValues);

    form.setValues(fetchedValues);
  }, [form.setValues, form.refineCore.queryResult?.data?.data?.id, modal.visible]);
  // console.log(values);

  return (
    <Drawer zIndex={theme.zIndex.popup - 1} opened={modal.visible} onClose={modal.close} title={modal.title} padding="xl" size="md" position="right">
      <FormContext.FormProvider form={form}>
        <Stack spacing={0}>
          <TextField name="name" label="Name" helperText={errors.name} />
          <CheckboxGroup name="types" label="Types" helperText={errors.types}>
            <CheckboxGroupItem name="types" value="GUIDE" label="Guide" />
            <CheckboxGroupItem name="types" value="DRIVER" label="Driver" />
            <CheckboxGroupItem name="types" value="VEHICLE" label="Vehicle" />
          </CheckboxGroup>
          <Checkbox name="is_person" label="Is Person" helperText={errors.is_person} />
          <Collapse in={!!values.is_person}>
            <TextField name="email" label="Email" type="email" helperText={errors.email} />
            <TextField name="telephone" label="Telephone" type="tel" helperText={errors.telephone} />
            <Checkbox name="is_login_enabled" label="Is Login Enabled" helperText={errors.is_login_enabled} />
          </Collapse>
          <Button sx={{ mt: 2 }} fullWidth {...saveButtonProps}>
            Save
          </Button>
        </Stack>
      </FormContext.FormProvider>
    </Drawer>
  );
};

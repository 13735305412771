import { JsonTable } from "@app/components/json-table";
import { Show } from "@app/ui/refine-components/crud/show";
import { Box, Grid, Typography } from "@mui/joy";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { decodeHTML } from "entities";
import { flatten } from "flat";
import { mapValues } from "lodash";
import { TourcmsTour } from ".";
import { tourcmsTourResource } from "./resource";

const prepareObject = (obj: any) => mapValues(flatten(obj), (value) => decodeHTML(decodeHTML(value || "")));

export const TourcmsTourShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult, showId, setShowId } = useShow<TourcmsTour>({ resource: tourcmsTourResource.id });

  const tour = queryResult?.data?.data;
  const tourName = tour?.tour_name_long;
  const { pickup_points: x, images: y, new_booking: z, ...rest } = tour || {};
  const pickupPoints = tour?.pickup_points?.pickup;
  const images = tour?.images?.image;
  const rates = tour?.new_booking?.people_selection?.rate;
  return (
    <Show title={tourName}>
      <Grid container spacing={4}>
        <Grid xs={12} md={6}>
          <Typography level="h2">Tour</Typography>
          <JsonTable data={prepareObject(rest || {})} />
        </Grid>
        <Grid xs={12} md={6}>
          <Box mb={4}>
            {rates &&
              rates.map((rate, index) => (
                <>
                  <Typography level="h2">Rate {rate.rate_id}</Typography>
                  <JsonTable data={prepareObject(rate)} />
                </>
              ))}
          </Box>
          <Box mb={4}>
            {images &&
              images.map((image, index) => (
                <>
                  <Typography level="h2">Image {index + 1}</Typography>
                  <JsonTable data={prepareObject(image)} />
                </>
              ))}
          </Box>
          <Box mb={4}>
            {pickupPoints &&
              pickupPoints.map((pickup, index) => (
                <>
                  <Typography level="h2">Pickup {index + 1}</Typography>
                  <JsonTable data={prepareObject(pickup)} />
                </>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Show>
  );
};

import GlobalStyles from "@mui/joy/GlobalStyles";
import Sheet from "@mui/joy/Sheet";
import { Logo } from "../../../../components/logo";
import { ColorSchemeToggle } from "../color-scheme-toggle";
import { Hamburger } from "../hamburger";

export const Header = () => {
  return (
    <>
      <Sheet
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "var(--Header-height)",
          zIndex: 9995,
          py: 1,
          px: 2,
          gap: 1,
          boxShadow: "sm",
        }}
      >
        <GlobalStyles
          styles={(theme) => ({
            ":root": {
              "--Header-height": "52px",
              [theme.breakpoints.up("md")]: {
                "--Header-height": "0px",
              },
            },
          })}
        />
        <Hamburger />
        <Logo variant="plain" sx={{ boxShadow: "none", mr: "auto" }} />
        <ColorSchemeToggle />
      </Sheet>
    </>
  );
};

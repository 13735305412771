import { Resource } from "@app/types/types";
import { ResourceProps } from "@refinedev/core";
import { RESOURCES_BASE_PATH } from "./constants";

const id = "resource-base";
const path = `${RESOURCES_BASE_PATH}/${id}`;

export const resourceBase: Resource = {
  id,
  path: `${RESOURCES_BASE_PATH}/${id}`,
  relativePath: `/${id}`,
  pages: {
    list: { relativePath: "/", path: `${path}/` },
    show: { relativePath: "/:id", path: `${path}/:id` },
  },
  language: {
    singular: "Resource",
    plural: "Resources",
  },
  api: {
    path: "/",
  },
  toRefineResource(): ResourceProps {
    return {
      name: this.id,
      identifier: this.id,
      list: this.pages.list.path,
      show: this.pages.show.path,
      meta: {
        audit: [],
        icon: undefined,
        label: this.language.plural,
        parent: undefined,
        canDelete: false,
      },
    };
  },
};

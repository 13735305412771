import { Auth0Provider } from "@auth0/auth0-react";
import { StyledEngineProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./providers/auth";

const auth0Config = {
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
};

if (!auth0Config.clientId || !auth0Config.domain) {
  throw new Error("Auth0 is not configured");
}

const authorizationParams = { redirect_uri: `${window.location.origin}` };

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <React.Suspense fallback="loading">
        <Auth0Provider
          domain={auth0Config.domain}
          clientId={auth0Config.clientId}
          authorizationParams={authorizationParams}
          useRefreshTokens
          cacheLocation="localstorage"
        >
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </Auth0Provider>
      </React.Suspense>
    </StyledEngineProvider>
  </React.StrictMode>
);

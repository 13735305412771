import { sumBy, uniq } from "lodash";
import { computeBooking } from "../tourcms-booking/computed";
import { TourcmsDeparture } from "./types";

export const computeDeparture = (departure: TourcmsDeparture) => {
  const tour = departure.product ?? {};
  const image = tour.images?.image?.[0]?.url ?? "";
  const bookings = departure.bookings ?? [];
  const bookings_computations = bookings.map(computeBooking);
  const all_rate_descriptions = uniq(
    bookings_computations.flatMap((booking_computation) => Object.keys(booking_computation.customer_count_by_rate_description))
  );
  const customer_count_by_rate_description = Object.fromEntries(
    all_rate_descriptions.map((rate_description) => [
      rate_description,
      sumBy(bookings_computations, (computation) => computation.customer_count_by_rate_description[rate_description] || 0),
    ])
  );
  const booking_sample_component = bookings[0]?.components?.component?.[0] ?? {};
  const departure_name = booking_sample_component.product_note;
  return { tour, image, bookings, booking_sample_component, departure_name, customer_count_by_rate_description };
};

export const enhanceDeparture = (departure: TourcmsDeparture) => {
  const computed = computeDeparture(departure);
  return { ...departure, computed };
};

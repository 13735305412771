import { Chip, Grid, Typography, useTheme } from "@mui/joy";
import { AlertTriangle } from "react-feather";
import { TourcmsBooking } from ".";
import { computeBooking } from "./computed";

export const TelephonesChips = ({ booking }: { booking: TourcmsBooking }) => {
  const theme = useTheme();
  const computed = computeBooking(booking);
  const telephones = computed.telephones;
  const dangerColor = theme.palette.danger.softColor;
  return (
    <>
      {telephones.map((telephone) => (
        <Chip size="sm" variant="outlined" color="neutral">
          <Grid container gap={0.5} alignItems="center">
            {telephone.invalid && <AlertTriangle color={dangerColor} size={"14"} />}
            <Typography level="body3" sx={{ fontWeight: (theme) => theme.fontWeight.sm }}>
              {telephone.number}
            </Typography>
          </Grid>
        </Chip>
      ))}
    </>
  );
};

export const EmailChip = ({ booking }: { booking: TourcmsBooking }) => {
  const email = booking.lead_customer_email;
  return (
    <>
      {email && (
        <Chip size="sm" variant="outlined" color="neutral">
          <Typography level="body3" sx={{ fontWeight: (theme) => theme.fontWeight.sm }}>
            {email}
          </Typography>
        </Chip>
      )}
    </>
  );
};

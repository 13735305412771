import { CenteredLoading } from "@app/components/common";
import { TourcmsDeparture } from "@app/resources/tourcms-departure";
import { CheckCircleTwoTone } from "@mui/icons-material";
import { Box, Container, Grid, Stack, Typography } from "@mui/joy";
import { useList } from "@refinedev/core";
import dayjs from "dayjs";
import { DepartureDetails } from "../resources/tourcms-departure/show";

export const NextDepartureShow: React.FC<any> = () => {
  const from = dayjs().format("YYYY-MM-DD");
  const to = dayjs().add(1, "week").format("YYYY-MM-DD");
  const { data, isInitialLoading } = useList<TourcmsDeparture>({
    resource: "tourcms-departures",
    filters: [
      { field: "start_date", operator: "gte", value: from },
      { field: "start_date", operator: "lt", value: to },
    ],
  });

  const departures = data?.data ?? undefined;
  const departure = departures && departures[0];

  if (isInitialLoading)
    return (
      <Stack height={"100%"} alignItems="center" justifyItems="center">
        <CenteredLoading size="lg" />
      </Stack>
    );

  return departure ? (
    <>
      <Box mb={2}>
        <Typography level="h1" fontSize="xl4">
          Your next departure
        </Typography>
      </Box>
      <DepartureDetails departure={departure} />
    </>
  ) : (
    <NoDeparturesUpcoming />
  );
};

export const NoDeparturesUpcoming: React.FC = () => {
  return (
    <Container
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box display="flex" gap="36px" justifyContent="center" flexDirection="column">
        <Typography level="h3" color="primary">
          No upcoming departures.
        </Typography>
        <Grid container width={"100%"} justifyContent={"center"}>
          <CheckCircleTwoTone sx={{ width: 150 }} />
        </Grid>
      </Box>
    </Container>
  );
};

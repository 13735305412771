import { TourcmsBooking } from "./types";

export const computeBooking = (booking: TourcmsBooking) => {
  const home_tel = booking.lead_customer_tel_home_e164 || booking.lead_customer_tel_home;
  const home_tel_exists_and_invalid = booking.lead_customer_tel_home && !booking.lead_customer_tel_home_e164;
  const mobile_tel = booking.lead_customer_tel_mobile_e164 || booking.lead_customer_tel_mobile;
  const mobile_tel_exists_and_invalid = booking.lead_customer_tel_mobile && !booking.lead_customer_tel_mobile_e164;

  const telephones = [
    { number: home_tel, type: "home", invalid: home_tel_exists_and_invalid },
    { number: mobile_tel, type: "mobile", invalid: mobile_tel_exists_and_invalid },
  ].filter((telephone) => telephone.number);

  const customer_count_by_rate_description = Object.fromEntries(
    booking.components?.component.map((component) => [component.rate_description, Number(component.sale_quantity) || 0])
  );

  const sample_component = booking.components?.component?.[0] ?? {};

  const pickup_time = sample_component.pickup_time;
  const is_custom_pickup = String(sample_component.pickup_id) === "-1" ? true : false;
  const pickup_name = sample_component.pickup_name;
  const pickup_note = sample_component.pickup_note;

  const tourcms_pos_url = `https://pos.tourcms.com/#/bookings/${booking.channel_id}/${booking.booking_id}/products`;

  const result = { telephones, customer_count_by_rate_description, pickup_time, is_custom_pickup, pickup_name, pickup_note, tourcms_pos_url };

  return result;
};

export const enhanceBooking = (booking: TourcmsBooking) => {
  const computed = computeBooking(booking);
  return { ...booking, computed };
};

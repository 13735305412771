// import { Checkbox, CheckboxGroup, CheckboxGroupItem } from "@app/ui/form/checkbox";
// import { TextField } from "@app/ui/form/text-field";
// import { Modal } from "@mantine/core";
// import { Button, Stack } from "@mui/joy";
// import { Collapse } from "@mui/material";
import { BaseRecord, HttpError } from "@refinedev/core";
import { UseModalFormReturnType, useModalForm as useMantineModalForm } from "@refinedev/mantine";
// import { Resource, resourceResource } from ".";

export interface ModalFormProps<Record extends BaseRecord, FormValues> {
  form: UseModalFormReturnType<Record, HttpError, FormValues>;
}

export const useModalForm = () => {
  const create = useMantineModalForm({
    refineCoreProps: { action: "create" },
    initialValues: {},
    syncWithLocation: true,
  });

  const edit = useMantineModalForm({
    refineCoreProps: { action: "edit" },
    initialValues: {},
    syncWithLocation: true,
  });

  // useEffect(() => {
  //   console.log(edit.refineCore.id, edit.refineCore.queryResult?.data?.data);
  //   setInitialValues(edit.refineCore.queryResult?.data?.data);
  // }, [edit.refineCore.id]);

  return { create, edit };
};

import { Logo } from "@app/components/logo";
import { useAuth } from "@app/providers/auth";
import { Box, Container, Grid, Typography } from "@mui/joy";
import { NextDepartureShow } from "./next-departure";

export const HomePage: React.FC = () => {
  const { isGlobalAdministrator } = useAuth();
  if (!isGlobalAdministrator) return <NextDepartureShow />;
  return (
    <Container
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box display="flex" gap="36px" justifyContent="center" flexDirection="column">
        <Typography level="h3" color="primary">
          Gray Line Hub: Where data gets together for a serious party.
        </Typography>
        <Grid container width={"100%"} justifyContent={"center"}>
          <Logo sx={{ width: 150 }} />
        </Grid>
      </Box>
    </Container>
  );
};

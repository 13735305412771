
import { AuthenticationLoadingPage } from "@app/components/common";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export const Login: React.FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated]);

  return <AuthenticationLoadingPage />;
};

import { Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import { notificationProvider, RefineSnackbarProvider } from "@refinedev/mui";

import { dataProvider } from "@app/providers/data";
import { Container } from "@mui/joy";
import routerBindings, { UnsavedChangesNotifier } from "@refinedev/react-router-v6";
import { BrowserRouter } from "react-router-dom";
import { AuthenticationLoadingPage } from "./components/common/loading";
import { useAuth } from "./providers/auth";
import { useRefineResources } from "./providers/resources";
import { RoutesProvider } from "./providers/routes";
import { ThemeProvider } from "./providers/theme";
import { Breadcrumb } from "./ui/refine-components/layout/breadcrumb";

import "./style.css";
function App() {
  const resources = useRefineResources();
  const { authProvider, isLoading } = useAuth();
  if (isLoading) {
    return (
      <Container>
        <AuthenticationLoadingPage />
      </Container>
    );
  }

  return (
    <BrowserRouter>
        <ThemeProvider>
          <RefineKbarProvider>
            <RefineSnackbarProvider>
              <Refine
                dataProvider={{
                  default: dataProvider({ apiUrl: import.meta.env.VITE_LOCAL_BACKEND_URL || "https://focus.mrtripp.ro" }),
                }}
                notificationProvider={notificationProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                resources={resources}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  breadcrumb: <Breadcrumb />,
                }}
              >
                <RoutesProvider />
                <RefineKbar />
                <UnsavedChangesNotifier />
              </Refine>
            </RefineSnackbarProvider>
          </RefineKbarProvider>
        </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;

import { Route, Routes } from "react-router-dom";
import { resourceResource } from "./resource";
import { ResourceRoutes } from "./resource/resource";
import { tourcmsBookingResource } from "./tourcms-booking";
import { TourcmsBookingRoutes } from "./tourcms-booking/resource";
import { tourcmsDepartureResource } from "./tourcms-departure";
import { TourcmsDepartureRoutes } from "./tourcms-departure/resource";
import { tourcmsTourResource } from "./tourcms-tour";
import { TourcmsTourRoutes } from "./tourcms-tour/resource";

export const resources = [tourcmsBookingResource, tourcmsTourResource, tourcmsDepartureResource, resourceResource];

export const refineResources = resources.map((resource) => resource.toRefineResource());
// console.log(refineResources);
export const ResourcesRoutes = () => (
  <Routes>
    {/* <Route index element={<NavigateToResource resource={tourcmsBookingResource.id} />} /> */}
    <Route path={`${tourcmsBookingResource.relativePath}/*`} element={<TourcmsBookingRoutes />} />
    <Route path={`${tourcmsTourResource.relativePath}/*`} element={<TourcmsTourRoutes />} />
    <Route path={`${tourcmsDepartureResource.relativePath}/*`} element={<TourcmsDepartureRoutes />} />
    <Route path={`${resourceResource.relativePath}/*`} element={<ResourceRoutes />} />
  </Routes>
);

import { List } from "@app/ui/refine-components/crud/list";
import { Sheet, Typography } from "@mui/joy";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { IResourceComponentsProps, useGetToPath } from "@refinedev/core";
import { useDataGrid } from "@refinedev/mui";
import { decodeHTML } from "entities";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { TourcmsTour, tourcmsTourResource } from ".";
import { applyGridColumnDefaults } from "../common/list";

export const TourcmsTourList: React.FC<IResourceComponentsProps> = () => {
  const { dataGridProps } = useDataGrid<TourcmsTour>({});
  const getToPath = useGetToPath();
  const columns = useMemo<GridColumns<TourcmsTour>>(
    () =>
      applyGridColumnDefaults([
        {
          field: "tour_id",
          headerName: "TourCMS ID",
          type: "number",
          width: 100,
          align: "left",
          headerAlign: "left",
          sortable: true,
        },
        {
          field: "tour_code",
          headerName: "Code",
          width: 200,
          align: "left",
          headerAlign: "left",
          sortable: true,
        },
        {
          field: "tour_name_long",
          headerName: "Name",
          flex: 4,
          renderCell: ({ row }) => (
            <Typography
              component={Link}
              to={getToPath({ action: "show", resource: tourcmsTourResource.toRefineResource(), meta: { id: row.id } })}
              level="body1"
              fontSize="sm"
              fontWeight="md"
            >
              {decodeHTML(row.tour_name_long ?? "")}
            </Typography>
          ),
        },
        {
          field: "from_price",
          headerName: "Starting Price",
          width: 200,
          align: "center",
          headerAlign: "center",
          renderCell: ({ row }) => decodeHTML(row.from_price_display ?? ""),
        },
      ]),
    []
  );
  // console.log(dataGridProps);
  return (
    <>
      <List resource={tourcmsTourResource.id} title="Tours">
        <Sheet
          variant="outlined"
          sx={{
            borderRadius: "md",
            flex: 1,
            minHeight: 0,
            overflow: "hidden",
          }}
        >
          <DataGrid
            {...dataGridProps}
            columns={columns}
            autoHeight
            sx={{
              border: 0,
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader": {
                backgroundColor: "var(--joy-palette-neutral-50)",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
          />
        </Sheet>
      </List>
    </>
  );
};

import { Box, FormControl, FormHelperText, FormHelperTextProps, Checkbox as JoyCheckbox, CheckboxProps as JoyCheckboxProps, List, ListItem } from "@mui/joy";
import { FormLabel, FormLabelProps } from "../form-label";

import { FormContext } from "@refinedev/mantine";
import { ReactNode, useCallback } from "react";

// CheckboxGroupContext

type CheckboxGroupProps = {
  name: string;
  label: string;
  helperText?: string | ReactNode;
  slotProps?: { label?: FormLabelProps; helper?: FormHelperTextProps };
  children: ReactNode;
};

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ children, name, label, helperText }: CheckboxGroupProps) => {
  const { getInputProps, errors } = FormContext.useFormContext();
  const error = errors[name];
  const formInputProps = getInputProps(name);
  return (
    <Box>
      <FormLabel>{label}</FormLabel>
      <Box role="group">
        <List
          orientation="horizontal"
          wrap
          sx={{
            "--List-gap": "8px",
            "--ListItem-radius": "20px",
          }}
        >
          {children}
        </List>
      </Box>
      <FormHelperText>{error}</FormHelperText>
    </Box>
  );
};

type CheckboxGroupItemProps = JoyCheckboxProps & {
  name: string;
  label: string;
  helperText?: string | ReactNode;
};

export const CheckboxGroupItem: React.FC<CheckboxGroupItemProps> = ({ name, label, helperText, value, ...checkboxProps }: CheckboxGroupItemProps) => {
  const { getInputProps, errors, values, setFieldValue } = FormContext.useFormContext();
  const error = errors[name];
  const formInputProps = { ...getInputProps(name) };

  const array = values[name] || [];

  const onChange = useCallback(
    (event) => {
      if (event.target.checked) {
        setFieldValue(name, array.includes(value) ? array : [...array, value]);
      } else {
        setFieldValue(
          name,
          array.filter((item) => value !== item)
        );
      }
    },
    [array, name, setFieldValue]
  );

  const isChecked = array.includes(value);
  return (
    <ListItem key={String(value)}>
      <JoyCheckbox
        name={name}
        label={label}
        overlay
        disableIcon
        variant="soft"
        size="sm"
        {...formInputProps}
        {...checkboxProps}
        checked={isChecked}
        onChange={onChange}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </ListItem>
  );
};

type CheckboxProps = JoyCheckboxProps & {
  name: string;
  label: string;
  helperText?: string | ReactNode;
  slotProps?: { label?: FormLabelProps; helper?: FormHelperTextProps };
};

export const Checkbox: React.FC<CheckboxProps> = ({ children, name, label, helperText, ...checkboxProps }: CheckboxProps) => {
  const { getInputProps, errors } = FormContext.useFormContext();
  const error = errors[name];
  const formInputProps = getInputProps(name);
  const isChecked = formInputProps.value ?? checkboxProps.value ?? "1";
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <JoyCheckbox {...formInputProps} {...checkboxProps} checked={isChecked} />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

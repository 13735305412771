import { Show } from "@app/ui/refine-components/crud/show";
import { keyframes } from "@emotion/react";
import { Call, GroupsOutlined, Hail, Payment, Sms } from "@mui/icons-material";
import { Box, Button, Card, Chip, Grid, ListItemButton, Sheet, Stack, Typography } from "@mui/joy";
import { Backdrop } from "@mui/material";
import * as Accordion from "@radix-ui/react-accordion";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import dayjs from "dayjs";
import { decodeHTML } from "entities";
import { chain } from "lodash";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TourcmsDeparture } from ".";
import { TourcmsBooking } from "../tourcms-booking";
import { enhanceBooking } from "../tourcms-booking/computed";
import { computeDeparture } from "./computed";
import { tourcmsDepartureResource } from "./resource";

export const TourcmsDepartureShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<TourcmsDeparture>({ resource: tourcmsDepartureResource.id });
  const departure = queryResult?.data?.data;
  return <Show wrapperProps={{}}>{departure && <DepartureDetails departure={departure} />}</Show>;
};

export const DepartureDetails: React.FC<{ departure: TourcmsDeparture; actions: React.ReactNode }> = ({ departure, actions }) => {
  const { tour, image, bookings, booking_sample_component, departure_name, customer_count_by_rate_description } = computeDeparture(departure);
  const date = dayjs(departure.start_date);
  const day = date.format("D");
  const month = date.format("MMMM");
  const year = date.format("YYYY");
  const dayOfWeek = date.format("dddd");

  const pickupEntries = chain(bookings)
    .groupBy(
      (booking) =>
        `${booking.components?.component?.[0]?.pickup_time || "00:00"}_${booking.components?.component?.[0]?.pickup_name}${
          String(booking.components?.component?.[0]?.pickup_id) === "-1" ? booking.components?.component?.[0]?.pickup_note : ""
        }`
    )
    .entries()
    .sortBy(([pickup, bookings]) => pickup)
    .value();

  const [openBooking, setOpenBooking] = useState<string | undefined>(undefined);

  return (
    <>
      <Sheet variant="solid" color="primary" invertedColors sx={{ mx: -3 }}>
        <Box px={4} py={2}>
          <Stack>
            <Typography fontSize="xl2" fontWeight="md">
              {day}
              <Typography fontWeight="sm"> {month} </Typography> {dayOfWeek} <Typography fontWeight="sm"> {year} </Typography>
            </Typography>
            <Typography level="h6">{tour.tour_name}</Typography>
            <Typography level="h6">{departure_name}</Typography>
          </Stack>
        </Box>
      </Sheet>
      {actions}
      <Sheet variant="soft" color="primary" invertedColors sx={{ mx: -3 }}>
        <Box px={4} py={2}>
          <Stack direction="row">
            <Typography level="h5">{departure.customer_count} Total PAX</Typography>
            <Typography flexGrow={1} textAlign="right">
              {Object.entries(customer_count_by_rate_description).map(([rate_description, count]) => (
                <Chip variant="soft" size="md" sx={{ ml: 1 }}>
                  {count} {rate_description}
                </Chip>
              ))}
            </Typography>
          </Stack>
          <Grid container direction="row" spacing={2}>
            <Grid>
              {departure.guide_resource && (
                <Chip variant="solid" size="lg">
                  {departure.guide_resource?.name} (Guide)
                </Chip>
              )}
            </Grid>
            <Grid>
              {departure.driver_resource && (
                <Chip variant="solid" size="lg">
                  {departure.driver_resource?.name} (Driver)
                </Chip>
              )}
            </Grid>
            <Grid>
              {departure.vehicle_resource && (
                <Chip variant="solid" size="lg">
                  {departure.vehicle_resource?.name} (Vehicle)
                </Chip>
              )}
            </Grid>
          </Grid>
        </Box>
      </Sheet>
      <Accordion.Root type="single" value={openBooking} onValueChange={setOpenBooking}>
        <Stack py={2} spacing={3}>
          {pickupEntries.map(([pickup, bookings]) => (
            <Stack key={pickup} spacing={1}>
              <BookingGroup bookings={bookings} openBooking={openBooking} setOpenBooking={setOpenBooking} />
            </Stack>
          ))}
        </Stack>
      </Accordion.Root>
    </>
  );
};

const BookingGroup: React.FC<{ bookings: TourcmsBooking[]; openBooking: string | undefined; setOpenBooking: (newValue: string | undefined) => void }> = ({
  bookings,
  openBooking,
  setOpenBooking,
}) => {
  const booking = enhanceBooking(bookings[0]);
  return (
    <>
      <Typography fontWeight="md" fontSize={{ xs: "sm", md: "md" }}>
        <Chip color="primary" size="sm">
          {booking.computed.pickup_time || "--:--"}
        </Chip>{" "}
        &nbsp;
        <Typography color="primary">
          {booking.computed.is_custom_pickup ? `On request: ${booking.computed.pickup_note}` : booking.computed.pickup_name || "No pickup name"}
        </Typography>
      </Typography>
      {bookings.map((booking) => (
        <Booking key={booking.id} openBooking={openBooking} setOpenBooking={setOpenBooking} booking={booking} />
      ))}
    </>
  );
};

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
});

const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
});

const Booking: React.FC<{ booking: TourcmsBooking; openBooking: string | undefined; setOpenBooking: (newValue: string | undefined) => void }> = ({
  booking,
  openBooking,
  setOpenBooking,
}) => {
  const customer_balance = booking.balance_owed_by === "C" ? Number(booking.balance) || 0 : 0;
  const telephone = booking.lead_customer_tel_home_e164 || booking.lead_customer_tel_mobile_e164;
  const call = telephone && `tel:${telephone}`;
  const sms = telephone && `sms:${telephone}`;
  const closeBooking = () => setOpenBooking("0");
  const isOpen = booking.id === openBooking;
  return (
    <>
      <Backdrop open={isOpen} onClick={closeBooking} sx={{ zIndex: 10000 }} />
      <Accordion.Item value={booking.id}>
        <Card variant="plain" color="neutral" sx={{ p: 0, gap: 0, overflow: "hidden", zIndex: isOpen ? 10000 + 1 : 0 }}>
          <ListItemButton
            variant="plain"
            component={Accordion.Trigger}
            disabled={isOpen}
            sx={{
              width: "100%",
              textAlign: "left",
              alignItems: "start",
              fontWeight: 400,
              p: 2,
            }}
          >
            <Typography sx={{ display: "flex", alignItems: "center" }} fontSize="sm">
              <Hail sx={{ fontSize: 16 }} /> &nbsp;
              <Typography>{booking.lead_customer_name}</Typography> &nbsp; &nbsp;
              <GroupsOutlined sx={{ fontSize: 16 }} /> &nbsp;
              <Typography>{booking.customer_count}</Typography> &nbsp; &nbsp;
              {customer_balance > 0 && (
                <>
                  <Payment sx={{ fontSize: 16 }} /> &nbsp;
                  <Typography color="warning" fontWeight="lg">
                    {decodeHTML(decodeHTML(booking.balance_display))}
                  </Typography>
                  &nbsp; &nbsp;
                </>
              )}
            </Typography>
          </ListItemButton>
          <Box
            component={Accordion.Content}
            sx={{
              overflow: "hidden",
              // p: 1.5, ⚠️ Cannot use padding here, otherwise the animation is lagging. Not sure why.
              '&[data-state="open"]': {
                animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
              },
              '&[data-state="closed"]': {
                animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
              },
            }}
          >
            <Stack direction="row" spacing={2} px={2} pb={2}>
              <Link to={call || ""}>
                <Button disabled={!call} size="md" color="success">
                  <Call />
                  &nbsp; Call
                </Button>
              </Link>
              <Link to={sms || ""}>
                <Button disabled={!sms} size="md" color="warning">
                  <Sms /> &nbsp;Text
                </Button>
              </Link>
            </Stack>
          </Box>
        </Card>
      </Accordion.Item>
    </>
  );
};

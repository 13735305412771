import { ResourceProps } from "@refinedev/core";
import React from "react";

export type Menu = MenuItem[];
export type MenuItem = {
  key: string;
  label: string;
  icon?: () => React.JSX.Element;
  listItem?: () => React.JSX.Element;
  path?: string;
  selected?: boolean;
  preview?: boolean;
  bottom?: boolean;
  items?: MenuItem[];
  roles?: string[];
};

export enum ThemeMode {
  LIGHT = "light",
  DARK = "dark",
}

export interface Resource {
  id: string;
  path: string;
  relativePath: string;
  pages: {
    list: { relativePath: string; path: string };
    show: { relativePath: string; path: string };
  };
  language: {
    singular: string;
    plural: string;
  };
  api: {
    path: string;
  };
  toRefineResource: () => ResourceProps;
}

import { List } from "@app/ui/refine-components/crud/list";
import { useModalForm } from "@app/ui/refine-extensions/modal/drawer-form";
import { Box, Chip, Link, Sheet, Typography } from "@mui/joy";
import { Stack } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { IResourceComponentsProps, useGetToPath } from "@refinedev/core";
import { useDataGrid } from "@refinedev/mui";
import { useMemo } from "react";
import { Resource, resourceResource } from ".";
import { applyGridColumnDefaults } from "../common/list";
import { EditResourceDrawer } from "./edit-drawer";

export const ResourceList: React.FC<IResourceComponentsProps> = () => {
  const { dataGridProps } = useDataGrid<Resource>({});
  const getToPath = useGetToPath();

  const { create, edit } = useModalForm();

  const columns = useMemo<GridColumns<Resource>>(
    () =>
      applyGridColumnDefaults([
        // {
        //   field: "id",
        //   headerName: "TourCMS ID",
        //   type: "number",
        //   width: 100,
        //   align: "left",
        //   headerAlign: "left",
        //   sortable: true,
        // },
        {
          field: "name",
          headerName: "Name",
          width: 200,
          align: "left",
          headerAlign: "left",
          sortable: true,
          renderCell: ({ row }: { row: Resource }) => <Link onClick={() => edit.modal.show(row.id)}>{row.name}</Link>,
        },
        {
          field: "types",
          headerName: "Resource Type",
          flex: 4,
          renderCell: ({ row }: { row: Resource }) => (
            <Stack direction="row" spacing={1}>
              {row.types?.map?.((type) => (
                <Chip size="sm" key={type}>
                  {type}
                </Chip>
              ))}
            </Stack>
          ),
        },
        {
          field: "email",
          headerName: "Login",
          flex: 4,
          renderCell: ({ row }: { row: Resource }) => (
            <Box>
              {[row.email, row.telephone]
                .filter((text) => !!text)
                .map((text) => (
                  <Typography key={text}>{text}</Typography>
                ))}
            </Box>
          ),
        },
      ]),
    []
  );

  return (
    <>
      <EditResourceDrawer form={create} />
      <EditResourceDrawer form={edit} />
      <List resource={resourceResource.id} title="Resource" createButtonProps={{ onClick: () => create.modal.show() }}>
        <Sheet
          variant="outlined"
          sx={{
            borderRadius: "md",
            flex: 1,
            minHeight: 0,
            overflow: "hidden",
          }}
        >
          <DataGrid
            {...dataGridProps}
            columns={columns}
            autoHeight
            sx={{
              border: 0,
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader": {
                backgroundColor: "var(--joy-palette-neutral-50)",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
          />
        </Sheet>
      </List>
    </>
  );
};

import { FormControl, FormHelperText, FormHelperTextProps, Autocomplete as JoyAutocomplete } from "@mui/joy";
import { BaseRecord } from "@refinedev/core";
import { FormContext } from "@refinedev/mantine";
import { ReactNode, useCallback } from "react";
import { InlineLoading } from "../../../components/common/loading";
import { FormLabel, FormLabelProps } from "../form-label";
import { UseAutocompleteReturnType } from "./use-autocomplete";

type AutocompleteProps = {
  name: string;
  label: string;
  helperText?: string | ReactNode;
  autocomplete: UseAutocompleteReturnType<BaseRecord>;
  slotProps?: { label?: FormLabelProps; helper?: FormHelperTextProps };
};

export const Autocomplete: React.FC<AutocompleteProps> = ({ name, label, helperText, autocomplete, slotProps, ...autocompleteProps }: AutocompleteProps) => {
  const { getInputProps, errors, setFieldValue, values} = FormContext.useFormContext();
  const error = errors[name];
  const formInputProps = getInputProps(name);
  const onChange = useCallback(
    (event, value: any) => {
      setFieldValue(name, value.id);
    },
    [formInputProps.onChange]
  );
  const defaultValue = autocomplete.defaultValueQueryResult.data?.data?.[0] || null;
  console.log(name, defaultValue, values);
  const defaultValueFetchFinished = autocomplete.defaultValueQueryResult.fetchStatus === "idle";
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      {defaultValueFetchFinished && (
        <JoyAutocomplete {...autocomplete.autocompleteProps} {...autocompleteProps} defaultValue={defaultValue} onChange={onChange} />
      )}
      {!defaultValueFetchFinished && <InlineLoading size="sm" />}
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

import { Table } from "@mui/joy";
import { flatten } from "flat";

export const JsonTable = (props: { data: Record<string, any> }) => {
  const { data } = props;
  const flat = flatten(data);
  return (
    <Table>
      <thead>
        <tr>
          <th>Field</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(flat).map(([key, value]) => (
          <tr key={key}>
            <td>{key}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

import { JsonTable } from "@app/components/json-table";
import { Show } from "@app/ui/refine-components/crud/show";
import { Box, Grid, Typography } from "@mui/joy";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { decodeHTML } from "entities";
import { flatten } from "flat";
import { mapValues } from "lodash";
import { TourcmsBooking, tourcmsBookingResource } from ".";

const prepareObject = (obj: any) => mapValues(flatten(obj), (value) => decodeHTML(decodeHTML(String(value) || "")));

export const TourcmsBookingShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult, showId, setShowId } = useShow<TourcmsBooking>({ resource: tourcmsBookingResource.id });

  const booking = queryResult?.data?.data;
  let { payments: x, components: y, ...rest } = booking || {};
  const bookingId = booking?.booking_id;
  const payments = booking?.payments?.payment;
  const components = booking?.components?.component;
  // console.log(booking, payments, components);
  return (
    <Show title={`Booking TCM ${bookingId}`}>
      <Grid container spacing={4}>
        <Grid xs={12} md={6}>
          <Typography level="h2">Booking</Typography>
          <JsonTable data={prepareObject(rest)} />
        </Grid>
        <Grid xs={12} md={6}>
          <Box mb={4}>
            {payments &&
              payments.map((payment, index) => (
                <>
                  <Typography level="h2">Payment {index + 1}</Typography>
                  <JsonTable data={prepareObject(payment)} />
                </>
              ))}
          </Box>
          <Box mb={4}>
            {components &&
              components.map((component, index) => (
                <>
                  <Typography level="h2">Component {index + 1}</Typography>
                  <JsonTable data={prepareObject(component)} />
                </>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Show>
  );
};

import { LayoutContextProperties } from "@app/types/layout";
import React, { ReactNode, useContext, useState } from "react";

export const LayoutContext = React.createContext<LayoutContextProperties>({
  mobileSiderOpen: false,
  setMobileSiderOpen: () => undefined,
  menuPreviewKey: null,
  setMenuPreviewKey: () => {},
  closeSidebar: () => {},
});

export const LayoutContextProvider: React.FC<{
  children: ReactNode;
  initialSiderCollapsed?: boolean;
}> = ({ children }) => {
  const [menuPreviewKey, setMenuPreviewKey] = useState<string | null>(null);
  const [mobileSiderOpen, setMobileSiderOpen] = useState<boolean>(false);
  const closeSidebar = React.useCallback(() => {
    setMenuPreviewKey(null);
    setMobileSiderOpen(false);
  }, [setMenuPreviewKey, setMobileSiderOpen]);
  return (
    <LayoutContext.Provider
      value={{
        mobileSiderOpen,
        setMobileSiderOpen,
        menuPreviewKey,
        setMenuPreviewKey,
        closeSidebar,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = (): LayoutContextProperties => {
  const context = useContext(LayoutContext);
  return context;
};

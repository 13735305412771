import { Box, Button, Typography } from "@mui/joy";
import { useRefineContext, useResource, useRouterType, useTranslate } from "@refinedev/core";
import type { ListProps } from "@refinedev/mui";
import { CreateButton } from "@refinedev/mui";
import React from "react";
import { DownloadCloud, Table } from "react-feather";
import { Breadcrumb } from "../layout/breadcrumb";

/**
 * `<List>` provides us a layout for displaying the page.
 * It does not contain any logic but adds extra functionalities like a refresh button.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/mui/components/basic-views/list} for more details.
 */
export const List: React.FC<ListProps> = ({
  title,
  canCreate,
  children,
  createButtonProps,
  resource: resourceFromProps,
  breadcrumb: breadcrumbFromProps,
  wrapperProps,
  headerProps,
  contentProps,
  headerButtonProps,
  headerButtons,
}) => {
  const translate = useTranslate();
  const { options: { breadcrumb: globalBreadcrumb } = {} } = useRefineContext();

  const routerType = useRouterType();

  const { resource } = useResource(resourceFromProps);

  const isCreateButtonVisible = canCreate ?? ((resource?.canCreate ?? !!resource?.create) || createButtonProps);

  const breadcrumb = typeof breadcrumbFromProps === "undefined" ? globalBreadcrumb : breadcrumbFromProps;

  const breadcrumbComponent = typeof breadcrumb !== "undefined" ? <>{breadcrumb}</> ?? undefined : <Breadcrumb />;

  const defaultHeaderButtons = isCreateButtonVisible ? (
    <CreateButton resource={routerType === "legacy" ? resource?.route : resource?.identifier ?? resource?.name} {...createButtonProps} />
  ) : null;

  return (
    <Box {...wrapperProps}>
      {breadcrumbComponent}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 2,
          mb: 4,
          gap: 1,
          flexWrap: "wrap",
          "& > *": {
            minWidth: "clamp(0px, (500px - 100%) * 999, 100%)",
            flexGrow: 1,
          },
        }}
        {...(headerProps ?? {})}
      >
        <Typography level="h1" fontSize="xl4">
          {title}
        </Typography>
        <Box sx={{ flex: 999 }} />
        <Box sx={{ display: "flex", gap: 1, "& > *": { flexGrow: 1 } }}>
          <Box display="flex" gap="16px" {...headerButtonProps}>
            {headerButtons
              ? typeof headerButtons === "function"
                ? headerButtons({
                    defaultButtons: defaultHeaderButtons,
                  })
                : headerButtons
              : defaultHeaderButtons}
          </Box>
          <Button variant="outlined" color="neutral" startDecorator={<DownloadCloud />}>
            Download PDF
          </Button>
          <Button variant="outlined" color="neutral" startDecorator={<Table />}>
            Download CSV
          </Button>
        </Box>
      </Box>
      <Box {...(contentProps ?? {})}>{children}</Box>
    </Box>
  );
};

import { useLayoutContext } from "@app/providers/layout";
import { useMainMenu } from "@app/providers/menu";
import { useTheme } from "@mui/joy";
import Avatar from "@mui/joy/Avatar";
import Divider from "@mui/joy/Divider";
import GlobalStyles from "@mui/joy/GlobalStyles";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import Sheet from "@mui/joy/Sheet";
import { useGetIdentity } from "@refinedev/core";
import { Link } from "react-router-dom";
import { Logo } from "../../../../components/logo";

type IUser = {
  id: number;
  name: string;
  avatar: string;
};

export const FirstSidebar = () => {
  const { mobileSiderOpen, setMobileSiderOpen, closeSidebar } = useLayoutContext();
  const { data: user } = useGetIdentity<IUser>();
  const { items, selected, setPreviewKey, previewKey } = useMainMenu();
  const top = items.filter((item) => !item.bottom);
  const bottom = items.filter((item) => item.bottom);

  const theme = useTheme();

  return (
    <Sheet
      className="FirstSidebar"
      variant="soft"
      color="primary"
      invertedColors
      sx={{
        position: {
          xs: "fixed",
          md: "sticky",
        },
        transform: {
          xs: `translateX(calc(100% * (${mobileSiderOpen ? 1 : 0} - 1)))`,
          md: "none",
        },
        transition: "transform 0.4s",
        zIndex: theme.zIndex.popup - 1,
        height: "100dvh",
        width: "var(--FirstSidebar-width)",
        top: 0,
        p: 1.5,
        py: 3,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
        "& svg": {
          width: 20,
          height: 20,
        },
      }}
    >
      <GlobalStyles
        styles={{
          ":root": {
            "--FirstSidebar-width": "68px",
          },
        }}
      />
      <Logo />
      <List sx={{ "--ListItem-radius": "8px", "--List-gap": "12px" }}>
        {top.map((item) => (
          <ListItem>
            <ListItemButton
              {...(Array.isArray(item.items) && item.items.length > 0
                ? {
                    onClick: () => {
                      setPreviewKey(item.key === previewKey ? null : item.key);
                    },
                  }
                : item.path && { component: Link, to: item.path, onClick: closeSidebar })}
              {...(item.selected && { selected: true, variant: "solid", color: "primary" })}
            >
              {item.icon?.()}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List
        sx={{
          mt: "auto",
          flexGrow: 0,
          "--ListItem-radius": "8px",
          "--List-gap": "8px",
        }}
      >
        {bottom.map((item) => (
          <>
            {item.listItem?.()}
            {item.icon && (
              <ListItem>
                <ListItemButton>{item.icon?.()}</ListItemButton>
              </ListItem>
            )}
          </>
        ))}
      </List>
      <Divider />
      <Avatar variant="outlined" src={user?.avatar} alt={user?.name} />
    </Sheet>
  );
};

import { Autocomplete } from "@app/ui/form/autocomplete";
import { useAutocomplete } from "@app/ui/form/autocomplete/use-autocomplete";
import { ArrowRightAltRounded, Hail, PlaceOutlined, ScheduleOutlined } from "@mui/icons-material";
import { AspectRatio, Box, Button, Card, Divider, Grid, Link, Sheet, Stack, Typography } from "@mui/joy";
import { HttpError, useGetToPath } from "@refinedev/core";
import { FormContext, UseModalFormReturnType } from "@refinedev/mantine";
import dayjs from "dayjs";
import { lowerCase, startCase } from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { tourcmsDepartureResource } from ".";
import { Resource, resourceResource } from "../resource";
import { enhanceBooking } from "../tourcms-booking/computed";
import { computeDeparture } from "./computed";
import { TourcmsDeparture } from "./types";
interface FormValues {}

const resourceTypeFilter = (type: string) => [{ field: "types", operator: "elemMatch", value: type }];

const getResourceOptionLabel = (resource: Resource) => {
  return `${resource.name} (${resource.types?.map(lowerCase)?.map(startCase)?.join(", ")})`;
};

export const TourcmsDepartureQuickForm: React.FC<{ form: UseModalFormReturnType<TourcmsDeparture, HttpError, FormValues>; departure: TourcmsDeparture }> = ({
  form,
  departure,
}) => {
  const getToPath = useGetToPath();
  const guide = useAutocomplete<Resource>({
    resource: resourceResource.id,
    optionLabel: "name",
    filters: resourceTypeFilter("GUIDE"),
    defaultValue: departure.guide_resource_id || undefined,
  });
  const driver = useAutocomplete<Resource>({
    resource: resourceResource.id,
    optionLabel: "name",
    filters: resourceTypeFilter("DRIVER"),
    defaultValue: departure.driver_resource_id || undefined,
  });
  const vehicle = useAutocomplete<Resource>({
    resource: resourceResource.id,
    optionLabel: "name",
    filters: resourceTypeFilter("VEHICLE"),
    defaultValue: departure.vehicle_resource_id || undefined,
  });
  console.log(departure);
  if (!departure) return <></>;
  const date = dayjs(departure.start_date);
  const day = date.format("D");
  const month = date.format("MMMM");
  const year = date.format("YYYY");
  const dayOfWeek = date.format("dddd");
  const { tour, image, bookings, booking_sample_component, departure_name } = computeDeparture(departure);
  const url = getToPath({ action: "show", resource: tourcmsDepartureResource.toRefineResource(), meta: { id: departure.id } });
  const enhancedBookings = bookings.map(enhanceBooking);
  return (
    <>
      <Sheet variant="solid" color="primary" invertedColors>
        <Box px={4} py={2}>
          <Grid container alignItems="center" spacing={4}>
            <Grid
              sx={{
                width: {
                  xs: 50,
                  sm: 85,
                },
              }}
            >
              <AspectRatio
                ratio={4 / 3}
                sx={(theme) => ({
                  borderRadius: "xs",
                  width: "100%",
                  [theme.breakpoints.down("sm")]: {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                })}
              >
                <img alt="" src={image} style={{ display: "block" }} />
              </AspectRatio>
            </Grid>
            <Grid flexShrink={1} flexGrow={1} textAlign={"right"}>
              <Typography fontSize="xl2" fontWeight="md">
                {day}
                <Typography fontWeight="sm"> {month} </Typography> {dayOfWeek} <Typography fontWeight="sm"> {year} </Typography>
              </Typography>
              <Typography level="h6" noWrap textOverflow="ellipsis">
                {tour.tour_name}
              </Typography>
              <Typography level="h6">{departure_name}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
      </Sheet>
      <Sheet variant="plain" color="primary">
        <Stack py={3} pb={2} px={4} direction="row" spacing={4} alignItems={"center"} justifyContent="flex-start">
          {url && (
            <Link sx={{ width: "100%" }} component={RouterLink} target="_self" to={url}>
              <Button fullWidth component={Link} variant="solid" color="primary" startDecorator={<ArrowRightAltRounded />}>
                Navigate to Passenger List
              </Button>
            </Link>
          )}
        </Stack>
      </Sheet>
      <Divider>
        <Typography fontSize="xl" fontWeight="md" color="primary">
          Resources
        </Typography>
      </Divider>
      <FormContext.FormProvider form={form}>
        <Stack py={3} pb={2} px={4}>
          <Autocomplete name="guide_resource_id" label="Guide" autocomplete={guide} getOptionLabel={getResourceOptionLabel} />
          <Autocomplete name="driver_resource_id" label="Driver" autocomplete={driver} getOptionLabel={getResourceOptionLabel} />
          <Autocomplete name="vehicle_resource_id" label="Vehicle" autocomplete={vehicle} getOptionLabel={getResourceOptionLabel} />
          {form.isTouched() && <Button {...form.saveButtonProps}>Save changes</Button>}
        </Stack>
      </FormContext.FormProvider>
      <Divider>
        <Typography fontSize="xl" fontWeight="md" color="primary">
          Bookings
        </Typography>
      </Divider>
      <Grid py={2} px={4} container spacing={2}>
        {enhancedBookings.map((booking) => (
          <Grid xs={12}>
            <Card
              sx={{
                boxShadow: "none",
                // borderRadius: 0,
                "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder", cursor: "pointer" },
                rowGap: 0.5,
              }}
              variant="outlined"
            >
              <Box>
                <Typography sx={{ display: "flex", alignItems: "center" }} fontSize="sm" noWrap textOverflow="ellipsis">
                  <ScheduleOutlined sx={{ fontSize: 16 }} /> &nbsp;
                  <Typography fontWeight="lg" color={booking.computed.pickup_time ? "primary" : "danger"}>
                    {booking.computed.pickup_time || "no pickup time"}
                  </Typography>
                  &nbsp;&nbsp; &nbsp;&nbsp;
                  <PlaceOutlined sx={{ fontSize: 16 }} /> &nbsp;
                  <Typography
                    fontWeight="md"
                    color={booking.computed.pickup_name || (booking.computed.is_custom_pickup && booking.computed.pickup_note) ? "primary" : "danger"}
                  >
                    {booking.computed.is_custom_pickup ? `On request: ${booking.computed.pickup_note}` : booking.computed.pickup_name || "No pickup name"}{" "}
                  </Typography>
                </Typography>
              </Box>
              <Typography sx={{ display: "flex", alignItems: "center" }} fontSize="sm" noWrap textOverflow="ellipsis">
                <Hail sx={{ fontSize: 16 }} /> &nbsp;
                <Link overlay target="_blank" underline="none" href={booking.computed.tourcms_pos_url} sx={{ color: "text.tertiary" }}>
                  <Typography fontWeight="md">{booking.lead_customer_name}</Typography>
                </Link>
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

import { resources } from "@app/resources";
import { CrudFilters, DataProvider } from "@refinedev/core";
import axios, { AxiosInstance } from "axios";
import { unflatten } from "flat";
import { stringify } from "query-string";
// import { generateFilter, generateSort } from "./utils";

type MethodTypes = "get" | "delete" | "head" | "options";
type MethodTypesWithBody = "post" | "put" | "patch";

export const dataProvider = ({
  apiUrl,
  httpClient = axios,
}: {
  apiUrl: string;
  httpClient?: AxiosInstance;
}): Omit<Required<DataProvider>, "createMany" | "updateMany" | "deleteMany"> => ({
  getList: async ({ resource: resourceName, pagination, filters, sorters, meta }) => {
    console.log("getList", filters);
    const resource = resources.find((item) => item.id === resourceName);
    if (!resource) throw new Error(`Resource ${resourceName} not found`);
    const url = new URL(resource?.api.path, apiUrl).toString();

    const { current = 1, pageSize = 25, mode = "server" } = pagination ?? {};

    const { headers = {}, method = "GET" } = meta ?? {};

    const filter = filters && generateFilter(filters);

    const query: {
      page?: { number: number; size: number };
      sort?: string[];
      filter?: { [key: string]: string };
    } = {};

    if (mode === "server") {
      query.page = { number: current - 1, size: pageSize };
    }

    if (sorters && sorters.length > 0) {
      query.sort = sorters.map((sorter) => `${sorter.order === "desc" ? "-" : ""}${sorter.field}`);
    }

    if (filter) {
      query.filter = filter;
    }

    let search = "";
    if (meta?.search) {
      search = meta.search;
      query.text = search;
    }
    const response = await httpClient.request({ method, url: search ? url + `/search` : url, headers, params: query });
    // console.log(response.data);
    const items = response.data.items;
    const total = response.data.total || items.length;

    const data = items;
    return {
      data,
      total,
    };
  },

  getMany: async ({ resource: resourceName, ids, meta }) => {
    console.log("getMany", resourceName, ids);
    const resource = resources.find((item) => item.id === resourceName);
    if (!resource) throw new Error(`Resource ${resourceName} not found`);
    if (!Array.isArray(ids) || ids.filter((id) => !!id).length === 0) throw new Error(`Resource ${resourceName} getMany with no relevant ID`);
    const url = new URL(`${resource?.api.path}`, apiUrl).toString();

    const filter = { id: { $in: ids } };
    const query = { filter };
    const { headers = {}, method = "GET" } = meta ?? {};
    const response = await httpClient.request({ method, url, headers, params: query });

    const items = response.data.items;
    const total = response.data.total || items.length;

    const data = items;

    return {
      data,
    };
  },

  create: async ({ resource: resourceName, variables, meta }) => {
    const resource = resources.find((item) => item.id === resourceName);
    if (!resource) throw new Error(`Resource ${resourceName} not found`);
    const url = new URL(resource?.api.path, apiUrl).toString();

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "post";

    const { data } = await httpClient[requestMethod](url, variables, {
      headers,
    });

    return {
      data,
    };
  },

  update: async ({ resource: resourceName, id, variables, meta }) => {
    const resource = resources.find((item) => item.id === resourceName);
    if (!resource) throw new Error(`Resource ${resourceName} not found`);
    const url = new URL(resource?.api.path, apiUrl).toString() + `/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "patch";

    const { data } = await httpClient[requestMethod](url, variables, {
      headers,
    });

    return {
      data,
    };
  },

  getOne: async ({ resource: resourceName, id, meta }) => {
    console.log(`getOne ${resourceName}`, id, meta);
    const resource = resources.find((item) => item.id === resourceName);
    if (!resource) throw new Error(`Resource ${resourceName} not found`);
    if (!id) {
      return {};
      throw new Error(`GetOne ${resourceName} -> id not found`);
    }
    const url = new URL(`${resource?.api.path}/${id}`, apiUrl).toString();

    const { headers = {}, method = "GET" } = meta ?? {};

    const response = await httpClient.request({ method, url, headers });
    // console.log(response.data);
    const data = response.data.item;
    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables, meta }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "delete";

    const { data } = await httpClient[requestMethod](url, {
      data: variables,
      headers,
    });

    return {
      data,
    };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({ url, method, filters, sorters, payload, query, headers }) => {
    let requestUrl = `${url}?`;

    if (sorters) {
      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(","),
          _order: _order.join(","),
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload);
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url, {
          data: payload,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});

const generateFilter = (filters: CrudFilters) => {
  const result: { [key: string]: string } = {};
  filters.forEach((filter) => {
    if (filter.operator !== "or" && filter.operator !== "and" && "field" in filter) {
      if (filter.operator === "contains") {
        result[`${filter.field}.$regex`] = `/${filter.value}/i`;
        return;
      }
      result[`${filter.field}.$${filter.operator}`] = filter.value;
    }
  });
  return unflatten(result);
};

import { Resource } from "@app/types/types";
import { Route, Routes } from "react-router-dom";
import { RESOURCES_BASE_PATH } from "../common/constants";
import { resourceBase } from "../common/resource";
import { TourcmsDepartureList } from "./list";
import { TourcmsDepartureShow } from "./show";

const id = "tourcms-departures";
const path = `${RESOURCES_BASE_PATH}/${id}`;
export const tourcmsDepartureResource: Resource = {
  ...resourceBase,
  id,
  path,
  relativePath: `/${id}`,
  pages: {
    list: { relativePath: "/", path: `${path}/` },
    show: { relativePath: "/:id", path: `${path}/:id` },
  },
  language: {
    singular: "Departure",
    plural: "Departures",
  },
  api: {
    path: "/tourcms/departures",
  },
};
export const TourcmsDepartureRoutes = () => (
  <Routes>
    <Route index path={tourcmsDepartureResource.pages.list.relativePath} Component={TourcmsDepartureList} />
    <Route path={tourcmsDepartureResource.pages.show.relativePath} Component={TourcmsDepartureShow} />
  </Routes>
);

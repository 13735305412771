import { Box, Grid, Typography } from "@mui/joy";
import { useBack, useGo, useNavigation, useRefineContext, useResource, useRouterType, useToPath, useTranslate } from "@refinedev/core";
import type { ShowProps } from "@refinedev/mui";
import { Breadcrumb, DeleteButton, DeleteButtonProps, EditButton, EditButtonProps, ListButtonProps, RefreshButtonProps } from "@refinedev/mui";
import React from "react";
import { BackButton } from "../../../components/buttons/back";
import { ListButton } from "../../../components/buttons/list";
import { RefreshButton } from "../../../components/buttons/refresh";

/**
 * `<Show>` provides us a layout for displaying the page.
 * It does not contain any logic but adds extra functionalities like a refresh button.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/mui/components/basic-views/show} for more details.
 */
export const Show: React.FC<ShowProps> = ({
  title,
  canEdit,
  canDelete,
  isLoading = false,
  children,
  resource: resourceFromProps,
  recordItemId,
  breadcrumb: breadcrumbFromProps,
  dataProviderName,
  wrapperProps,
  headerProps,
  contentProps,
  headerButtonProps,
  headerButtons,
  footerButtonProps,
  footerButtons,
  goBack: goBackFromProps,
}) => {
  const translate = useTranslate();
  const { options: { breadcrumb: globalBreadcrumb } = {} } = useRefineContext();

  const routerType = useRouterType();
  const back = useBack();
  const go = useGo();
  const { goBack, list: legacyGoList } = useNavigation();

  const { resource, action, id: idFromParams } = useResource(resourceFromProps);

  const goListPath = useToPath({
    resource,
    action: "list",
  });

  const id = recordItemId ?? idFromParams;

  const breadcrumb = typeof breadcrumbFromProps === "undefined" ? globalBreadcrumb : breadcrumbFromProps;

  const hasList = resource?.list && !recordItemId;
  const hasDelete = canDelete ?? resource?.meta?.canDelete ?? resource?.canDelete;
  const isDeleteButtonVisible = hasDelete && typeof id !== "undefined";
  const isEditButtonVisible = canEdit ?? resource?.canEdit ?? !!resource?.edit;

  const breadcrumbComponent = typeof breadcrumb !== "undefined" ? <>{breadcrumb}</> ?? undefined : <Breadcrumb />;

  const listButtonProps: ListButtonProps | undefined = hasList
    ? {
        ...(isLoading ? { disabled: true } : {}),
        resource: routerType === "legacy" ? resource?.route : resource?.identifier ?? resource?.name,
      }
    : undefined;
  const editButtonProps: EditButtonProps | undefined = isEditButtonVisible
    ? {
        ...(isLoading ? { disabled: true } : {}),
        resource: routerType === "legacy" ? resource?.route : resource?.identifier ?? resource?.name,
        recordItemId: id,
      }
    : undefined;
  const deleteButtonProps: DeleteButtonProps | undefined = isDeleteButtonVisible
    ? {
        ...(isLoading ? { disabled: true } : {}),
        resource: routerType === "legacy" ? resource?.route : resource?.identifier ?? resource?.name,
        recordItemId: id,
        onSuccess: () => {
          if (routerType === "legacy") {
            legacyGoList(resource?.route ?? resource?.name ?? "");
          } else {
            go({ to: goListPath });
          }
        },
        dataProviderName,
      }
    : undefined;
  const refreshButtonProps: RefreshButtonProps = {
    ...(isLoading ? { disabled: true } : {}),
    resource: routerType === "legacy" ? resource?.route : resource?.identifier ?? resource?.name,
    recordItemId: id,
    dataProviderName,
  };

  const defaultHeaderButtons = (
    <>
      {hasList && <ListButton {...listButtonProps} />}
      {isEditButtonVisible && <EditButton {...editButtonProps} />}
      {isDeleteButtonVisible && <DeleteButton {...deleteButtonProps} />}
      <RefreshButton {...refreshButtonProps} />
    </>
  );
  const hasBackAction = action !== "list" && typeof action !== "undefined";
  const onBackClick = hasBackAction && back;
  return (
    <Box {...(wrapperProps ?? {})}>
      {breadcrumbComponent}
      <Grid mt={2} mb={2} container gap={2}>
        {typeof goBackFromProps !== "undefined" ? goBackFromProps : <BackButton onClick={onBackClick} />}
        <Typography level="h1" fontSize="xl4">
          {title}
        </Typography>
        <Box sx={{ flex: 999 }} />
        <Box display="flex" gap={4} {...(headerButtonProps ?? {})}>
          {headerButtons
            ? typeof headerButtons === "function"
              ? headerButtons({
                  defaultButtons: defaultHeaderButtons,
                  deleteButtonProps,
                  editButtonProps,
                  listButtonProps,
                  refreshButtonProps,
                })
              : headerButtons
            : defaultHeaderButtons}
        </Box>
      </Grid>
      <Box {...(contentProps ?? {})}>{children}</Box>
      <Box sx={{ padding: "16px" }} {...(footerButtonProps ?? {})}>
        {footerButtons ? (typeof footerButtons === "function" ? footerButtons({ defaultButtons: null }) : footerButtons) : null}
      </Box>
    </Box>
  );
};

import { Box, Breadcrumbs, Link, Typography } from "@mui/joy";
import { matchResourceFromRoute, useBreadcrumb, useResource } from "@refinedev/core";
import { BreadcrumbProps } from "@refinedev/mui";
import React from "react";
import { ChevronRight, Home } from "react-feather";

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumbProps, showHome = true, hideIcons = false, meta }) => {
  const { breadcrumbs } = useBreadcrumb({ meta });
  const { resources } = useResource();
  const rootRouteResource = matchResourceFromRoute("/", resources);
  const selectedLinkProperties = {
    color: "primary",
    variant: "soft",
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Breadcrumbs
        size="sm"
        aria-label="breadcrumbs"
        separator={<ChevronRight size={16} />}
        sx={{
          "--Breadcrumbs-gap": "1rem",
          "--Icon-fontSize": "16px",
          fontWeight: "lg",
          color: "neutral.400",
          px: 0,
          ...(breadcrumbProps?.sx ?? {}),
        }}
        {...breadcrumbProps}
      >
        {showHome && (
          <Link underline="hover" color="neutral" fontSize="inherit" aria-label="Home" to="/">
            <Home size={17.5} />
          </Link>
        )}
        {showHome && (
          <Link underline="hover" color="neutral" fontSize="inherit" aria-label="Home" to="/">
            Dashboard
          </Link>
        )}
        {breadcrumbs.map(({ label, icon, href }, index) => {
          return (
            <>
              {href && (
                <Link underline="hover" color="neutral" fontSize="inherit" to={href} {...(index === breadcrumbs.length - 1 && selectedLinkProperties)}>
                  {label}
                </Link>
              )}
              {!href && (
                <Typography fontSize="inherit" color="neutral" {...(index === breadcrumbs.length - 1 && selectedLinkProperties)}>
                  {label}
                </Typography>
              )}
            </>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

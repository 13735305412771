import { HeroIcon } from "@app/components/common";
import { Menu } from "@app/types/types";
import { ColorSchemeToggleFirstMenuListItem } from "@app/ui/refine-components/layout/color-scheme-toggle";
import { TicketIcon } from "@heroicons/react/24/outline";
import { Calendar, Home, List, Search, Settings, Tool, User } from "react-feather";
import { useLocation } from "react-router-dom";
import { useAuth } from "./auth";
import { useLayoutContext } from "./layout";

/*
<HeroIcon IconComponent={HomeIcon} />
<HeroIcon IconComponent={MagnifyingGlassIcon} />
<HeroIcon IconComponent={Cog6ToothIcon} />
*/

const menu: Menu = [
  {
    key: "home",
    label: "Home",
    icon: () => <Home />,
    path: "/",
  },
  {
    key: "tourcms-departures",
    label: "Planner",
    icon: () => <Calendar />,
    path: "/resources/tourcms-departures",
  },
  {
    key: "operations",
    label: "Operations",
    icon: () => <Tool />,
    items: [
      {
        key: "resources",
        label: "Resources",
        path: "/resources/resource",
        icon: () => <User />,
      },
    ],
    roles: ["GlobalAdministrator"],
  },
  {
    key: "tourcms",
    label: "TourCMS",
    icon: () => <HeroIcon IconComponent={TicketIcon} />,
    items: [
      {
        key: "tourcms-bookings",
        label: "Search bookings",
        path: "/resources/tourcms-bookings",
        icon: () => <Search />,
      },
      {
        key: "tourcms-tours",
        label: "Tours",
        path: "/resources/tourcms-tours",
        icon: () => <List />,
      },
    ],
    roles: ["GlobalAdministrator"],
  },
  { key: "darkmode", label: "Dark mode", listItem: () => <ColorSchemeToggleFirstMenuListItem />, bottom: true },
  { key: "settings", label: "Settings", path: "/settings", icon: () => <Settings />, bottom: true, roles: ["GlobalAdministrator"] },
];

export const useMainMenu = () => {
  const { isGlobalAdministrator } = useAuth();
  const { menuPreviewKey: previewKey, setMenuPreviewKey: setPreviewKey } = useLayoutContext();
  const location = useLocation();
  const items = menu
    .filter((item) => isGlobalAdministrator || !item.roles || !item.roles?.includes("GlobalAdministrator"))
    .map((item) => {
      const itemSelected = item.path && location.pathname.startsWith(item.path);
      const childrenSelected = item.items && item.items.find((subItem) => subItem.path && location.pathname.startsWith(subItem.path));
      const selected = item.path !== "/" && (itemSelected || childrenSelected);
      return { ...item, selected, preview: item.key === previewKey };
    });
  // console.log(items);
  // console.log(`Preview key: ${previewKey}`);
  return {
    items,
    selected: items.find((item) => item.selected),
    setPreviewKey,
    previewKey,
    preview: items.find((item) => item.preview),
  };
};
export const useSecondaryMenu = () => {
  const mainMenu = useMainMenu();

  const items = mainMenu.selected?.items || mainMenu.selected?.items || [];

  return { items };
};

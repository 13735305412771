import { Resource } from "@app/types/types";
import { Route, Routes } from "react-router-dom";
import { RESOURCES_BASE_PATH } from "../common/constants";
import { resourceBase } from "../common/resource";
import { ResourceList } from "./list";

const id = "resource";
const path = `${RESOURCES_BASE_PATH}/${id}`;
export const resourceResource: Resource = {
  ...resourceBase,
  id,
  path,
  relativePath: `/${id}`,
  pages: {
    list: { relativePath: "/", path: `${path}/` },
    show: { relativePath: "/:id", path: `${path}/:id` },
  },
  language: {
    singular: "Resource",
    plural: "Resources",
  },
  api: {
    path: "/resources",
  },
};
export const ResourceRoutes = () => (
  <Routes>
    <Route index path={resourceResource.pages.list.relativePath} Component={ResourceList} />
  </Routes>
);

import { Button } from "@mui/joy";
import { pickNotDeprecated, useCan, useNavigation, useResource, useTranslate, userFriendlyResourceName } from "@refinedev/core";
import type { ListButtonProps } from "@refinedev/mui";
import React from "react";
import { Table } from "react-feather";
import { Link } from "react-router-dom";

/**
 * `<ListButton>` is using uses Material UI {@link https://mui.com/components/buttons/ `<Button>`} component.
 * It uses the  {@link https://refine.dev/docs/core/hooks/navigation/useNavigation#list `list`} method from {@link https://refine.dev/docs/core/hooks/navigation/useNavigation `useNavigation`} under the hood.
 * It can be useful when redirecting the app to the list page route of resource}.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/mui/components/buttons/list-button} for more details.
 */
export const ListButton: React.FC<ListButtonProps> = ({
  resource: resourceNameFromProps,
  resourceNameOrRouteName,
  hideText = false,
  accessControl,
  svgIconProps,
  meta,
  children,
  onClick,
  ...rest
}) => {
  const accessControlEnabled = accessControl?.enabled ?? true;
  const hideIfUnauthorized = accessControl?.hideIfUnauthorized ?? false;
  const { listUrl: generateListUrl } = useNavigation();

  const translate = useTranslate();

  const { resource } = useResource(resourceNameFromProps ?? resourceNameOrRouteName);

  const { data } = useCan({
    resource: resource?.name,
    action: "list",
    queryOptions: {
      enabled: accessControlEnabled,
    },
    params: {
      resource,
    },
  });

  const disabledTitle = () => {
    if (data?.can) return "";
    else if (data?.reason) return data.reason;
    else return translate("buttons.notAccessTitle", "You don't have permission to access");
  };

  const listUrl = resource ? generateListUrl(resource, meta) : "";

  const { sx, ...restProps } = rest;

  if (accessControlEnabled && hideIfUnauthorized && !data?.can) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      color="neutral"
      component={Link}
      to={listUrl}
      replace={false}
      disabled={data?.can === false}
      startDecorator={!hideText && <Table {...svgIconProps} />}
      title={disabledTitle()}
      sx={{ minWidth: 0, ...sx }}
      {...restProps}
    >
      {hideText ? (
        <Table fontSize="small" {...svgIconProps} />
      ) : (
        children ??
        translate(
          `${resource?.name ?? resourceNameFromProps ?? resourceNameOrRouteName}.titles.list`,
          userFriendlyResourceName(
            resource?.meta?.label ?? resource?.label ?? resource?.name ?? pickNotDeprecated(resourceNameFromProps, resourceNameOrRouteName),
            "plural"
          )
        )
      )}
    </Button>
  );
};

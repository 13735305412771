import { CalendarIcon } from "@app/components/calendar-icon";
import { CenteredLoading } from "@app/components/common";
import { Box, Card, Stack, Typography, useTheme } from "@mui/joy";
import { Drawer } from "@mui/material";
import { IResourceComponentsProps, useList } from "@refinedev/core";
import { useModalForm } from "@refinedev/mantine";
import dayjs from "dayjs";
import { chain, sortBy } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { useMedia } from "react-use";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";
import { TourcmsDeparture } from ".";
import { TourcmsDepartureListItem } from "./list-item";
import { TourcmsDepartureQuickForm } from "./quick-form";

const paperProps = { sx: { width: { sm: "100%", md: 550 } } };
const initialValues = {
  // guide_resource_id: null,
  // driver_resource_id: null,
  // vehicle_resource_id: null,
};

export const TourcmsDepartureList: React.FC<IResourceComponentsProps> = () => {
  const from = dayjs().format("YYYY-MM-DD");
  const to = dayjs().add(1, "week").format("YYYY-MM-DD");
  const { data, isInitialLoading } = useList<TourcmsDeparture>({
    resource: "tourcms-departures",
    filters: [
      { field: "start_date", operator: "gte", value: from },
      { field: "start_date", operator: "lt", value: to },
    ],
  });

  const departures = data?.data ?? undefined;

  const editDrawerForm = useModalForm({
    refineCoreProps: { action: "edit", queryOptions: { enabled: true } },
    initialValues,
    syncWithLocation: false,
    validate: {},
  });
  const {
    modal: { show, close, visible },
  } = editDrawerForm;

  const showRef = useRef<(id: string) => void>();

  const callShowByRef = useCallback((id: string) => {
    editDrawerForm.reset();
    showRef.current?.(id);
  }, []);

  useEffect(() => {
    console.log("show changed");
    showRef.current = show;
  }, [show]);

  const drawerDeparture = departures && (departures.find((departure) => departure.id === editDrawerForm.refineCore.id) as TourcmsDeparture);
  return (
    <>
      <Drawer elevation={1} transitionDuration={100} open={visible} onClose={close} anchor="right" PaperProps={paperProps}>
        {visible && drawerDeparture && <TourcmsDepartureQuickForm form={editDrawerForm} departure={drawerDeparture} />}
      </Drawer>
      <List departures={departures} show={callShowByRef} />
      {isInitialLoading && (
        <Stack height={"100%"} alignItems="center" justifyItems="center">
          <CenteredLoading size="lg" />
        </Stack>
      )}
    </>
  );
};

const mediaSelectorPrefix = `@media `;

const List: React.FC<{ departures: TourcmsDeparture[]; show: () => void }> = ({ departures, show }) => {
  const days = chain(departures).sortBy("start_date").groupBy("start_date").value();
  const theme = useTheme();

  const spaceBetween = theme.spacing(2);

  const xs = useMedia(theme.breakpoints.down("xs").replace(mediaSelectorPrefix, ""));
  const sm = useMedia(theme.breakpoints.down("sm").replace(mediaSelectorPrefix, ""));
  const md = useMedia(theme.breakpoints.down("md").replace(mediaSelectorPrefix, ""));
  const lg = useMedia(theme.breakpoints.down("lg").replace(mediaSelectorPrefix, ""));
  const matches = [xs, sm, md, lg];
  const visibleSlidesByBreakpoint = [1, 1, 1, 2];
  const visibleSlides = visibleSlidesByBreakpoint[matches.findIndex((match) => !!match)] ?? 3;

  return (
    <Box mb={{ xs: -2, md: -5, lg: -10 }}>
      <Typography level="h1" mb={4}>
        Planner
      </Typography>
      <Box sx={{ userSelect: "none" }}>
        <Swiper spaceBetween={spaceBetween} slidesPerView={visibleSlides}>
          {Object.entries(days).map(([date, departures]) => (
            <SwiperSlide key={date}>
              <Day departures={departures} show={show} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

const Day: React.FC<{ departures: TourcmsDeparture[]; show: () => void }> = ({ departures, show }) => {
  const isoDate = departures[0].start_date;
  const date = dayjs(isoDate);
  const day = date.format("D");
  const month = date.format("MMMM");
  const dayOfWeek = date.format("dddd");

  const sortedDepartures = sortBy(departures, ["product.tour_id", "bookings[0].components.component[0].product_note"]);

  return (
    <>
      <Stack spacing={2} height="100%">
        <Card
          variant="solid"
          color="primary"
          invertedColors
          orientation="horizontal"
          sx={{
            transition: "250ms all",
            padding: {
              xs: 0,
              sm: 2,
            },
            boxShadow: "none",
            borderRadius: "sm",
            "&:hover": { boxShadow: "md", borderColor: "neutral.outlinedHoverBorder" },
          }}
        >
          <Stack
            direction={{
              xs: "row",
              sm: "row",
            }}
            width="100%"
            spacing={2}
          >
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: 42,
                },
                marginBottom: {
                  xs: -2,
                  sm: 0,
                },
              }}
            >
              <CalendarIcon text={day} />
            </Box>
            <Stack
              direction="row"
              sx={{
                padding: {
                  xs: 2,
                  sm: 0,
                },
              }}
              spacing={1}
              flex={1}
            >
              <Typography fontSize="xl3" fontWeight="md">
                {day}
              </Typography>
              <Typography fontSize="xl3" fontWeight="sm">
                {month}
              </Typography>
              <Typography fontSize="xl3" fontWeight="md">
                {dayOfWeek}
              </Typography>
            </Stack>
          </Stack>
        </Card>
        {sortedDepartures.map((departure) => (
          <TourcmsDepartureListItem departure={departure} selectDepartureId={show} />
        ))}
      </Stack>
    </>
  );
};

import { Box, CircularProgress, CircularProgressProps, Container, Stack, Typography } from "@mui/joy";

export const InlineLoading = (props: CircularProgressProps) => <CircularProgress />;

export const CenteredLoading = (props: CircularProgressProps) => (
  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
    <CircularProgress {...props} />
  </Box>
);

export const AuthenticationLoadingPage = () => (
  <Container sx={{ height: "100vh" }}>
    <Stack spacing={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
      <Box flexBasis={1}>
        <CenteredLoading size="lg" />
      </Box>
      <Box flexBasis={2}>
        <Typography level="h4">Authenticating...</Typography>
      </Box>
    </Stack>
  </Container>
);

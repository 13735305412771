import { Box, Chip, Grid } from "@mui/joy";
import { AlertTriangle, Check, Loader, X } from "react-feather";
import { CancelReason, Status, TourcmsBooking } from "./types";

const statusColorMap = {
  [Status.QUOTE]: "danger",
  [Status.PROVISIONAL]: "warning",
  [Status.CONFIRMED]: "success",
  [Status.CONFIRMED_AND_ARCHIVED]: "success",
};

const canceledStatusColor = "light";

const iconSize = 17.5;
const statusIconMap = {
  [Status.QUOTE]: () => <AlertTriangle size={iconSize} />,
  [Status.PROVISIONAL]: () => <Loader size={iconSize} />,
  [Status.CONFIRMED]: () => <Check size={iconSize} />,
  [Status.CONFIRMED_AND_ARCHIVED]: () => <Check size={iconSize} />,
};

const canceledStatusIcon = () => <X size={iconSize} />;

export const StatusChip = ({ booking }: { booking: TourcmsBooking }) => {
  const icon = booking.cancel_reason === CancelReason.NotCancelled ? statusIconMap[booking.status] : canceledStatusIcon;
  return (
    <Chip size="md" variant="soft" color={booking.cancel_reason === CancelReason.NotCancelled ? statusColorMap[booking.status] : canceledStatusColor}>
      <Grid container gap={0.5} alignItems="center">
        {icon?.()}
        <Box>{booking.status_text}</Box>
      </Grid>
    </Chip>
  );
};
